/**
 * External dependencies
 */
import { differenceInSeconds, formatDistance } from 'date-fns';
import { FC, useEffect, useState } from 'react';

/**
 * Internal dependencies
 */
import { UserHeader, UserModel } from '@/user';

type EventResponseHeaderProps = {
	author: UserModel;
	createdAt: string;
};

const EventResponseHeader: FC<EventResponseHeaderProps> = ({
	author,
	createdAt,
}) => {
	const [distance, setDistance] = useState(
		formatDistance(createdAt, new Date(), { addSuffix: true })
	);

	useEffect(() => {
		const diff = differenceInSeconds(new Date(), createdAt);

		setTimeout(
			() =>
				setDistance(
					formatDistance(createdAt, new Date(), { addSuffix: true })
				),
			(diff < 30 ? 31 - diff : 60) * 1000
		);
	}, [createdAt, distance]);

	return (
		<UserHeader
			user={author}
			align="right"
			size="large"
			subheading={distance}
		/>
	);
};

export default EventResponseHeader;
