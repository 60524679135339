/**
 * External dependencies
 */
import { Reducer } from 'redux';

/**
 * Internal dependencies
 */
import { setAuthReferrer } from '@/store/actions';
import initialState from '../../initialState';
import isAction from '../../isAction';

const referrer: Reducer<string | null> = (
	state = initialState.auth.referrer,
	action
) => (isAction(action, setAuthReferrer) ? action.payload : state);

export default referrer;
