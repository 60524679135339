/**
 * External dependencies
 */
import { FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import classes from './EventDetails.module.scss';

type EventDetailsProps = {
	title: string;
	description: string;
};

const cx = classNames.bind(classes);

const EventDetails: FC<EventDetailsProps> = ({ title, description }) => (
	<div className={cx('wrap')}>
		<p className={cx('title')}>{title}</p>
		<p className={cx('description')}>{description}</p>
	</div>
);

export default EventDetails;
