/**
 * External dependencies
 */
import { FC, useState } from 'react';
import { Popover, PopoverProps } from 'antd';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { Column, ModalHeader } from '@/components';
import classes from './ClosablePopover.module.scss';

const cx = classNames.bind(classes);

const ClosablePopover: FC<PopoverProps> = ({
	content,
	onOpenChange,
	open,
	overlayClassName,
	title,
	...props
}) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<Popover
			arrow={false}
			overlayClassName={cx('popover', overlayClassName)}
			content={
				<Column align="stretch">
					<ModalHeader
						onClose={(e) => {
							onOpenChange?.(false, e);
							setIsOpen(false);
						}}
						title={title}
					/>
					{typeof content === 'function' ? content() : content}
				</Column>
			}
			onOpenChange={onOpenChange ?? setIsOpen}
			open={open !== undefined ? open : isOpen}
			placement="bottomRight"
			align={{
				offset: [0, 0],
			}}
			trigger="click"
			{...props}
		/>
	);
};

export default ClosablePopover;
