/**
 * External dependencies
 */
import { FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { ReactComponent as CloseIcon } from '@/images/icon-circled-cross.svg';
import { Tag } from '@/forms/hooks/useTags/useTags';
import classes from './TagsInputRemove.module.scss';

const cx = classNames.bind(classes);

type TagsInputRemoveProps = {
	'aria-label': string;
	className?: string;
	index: number;
	onKeyDown: () => void;
	onRemove: () => void;
	tag: Tag;
};

const TagsInputRemove: FC<TagsInputRemoveProps> = ({
	onRemove,
	onKeyDown,
	className,
	tag,
	...props
}) => (
	<button
		className={cx('button', className)}
		onClick={onRemove}
		onKeyDown={onKeyDown}
		{...props}
	>
		<CloseIcon />
	</button>
);

export default TagsInputRemove;
