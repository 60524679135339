/**
 * Internal dependencies.
 */
import { ReactComponent as Calendar } from '@/images/icon-calendar-checked.svg';
import { ReactComponent as Clock } from '@/images/icon-clock.svg';
import { ReactComponent as Gearwheel } from '@/images/gearwheel.svg';
import { ReactComponent as QuestionMark } from '@/images/icon-question-mark.svg';
import { ReactComponent as Star } from '@/images/icon-star.svg';
import { Route } from '@/router/types';

export const primaryNavigation = [
	{
		label: 'Ongoing event',
		icon: <Clock />,
		pathname: Route.Home,
	},
	{
		label: 'Closed events',
		icon: <Calendar />,
		pathname: Route.EventsClosed,
	},
	{
		label: 'Your events',
		icon: <Star />,
		pathname: Route.JoinedEvents,
	},
];

export const secondaryNavigation = [
	{
		label: 'Support',
		icon: <QuestionMark />,
		pathname: Route.Support,
	},
	{
		label: 'Settings',
		icon: <Gearwheel />,
		pathname: Route.Account,
	},
];
