/**
 * External dependencies
 */
import { Drawer, DrawerProps } from 'antd';
import { FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import classes from './MobileDrawer.module.scss';
import ModalHeader from '../ModalHeader';
import { useSelector } from '@/store';

const cx = classNames.bind(classes);

const MobileDrawer: FC<DrawerProps> = ({
	children,
	className,
	rootClassName,
	rootStyle,
	title,
	...props
}) => (
	<Drawer
		className={cx('content', className)}
		closable={false}
		rootClassName={cx('root', rootClassName)}
		rootStyle={{
			...rootStyle,
			'--header-height': `${useSelector(({ ui }) => ui.headerHeight)}px`,
		}}
		size="large"
		{...props}
	>
		{title && <ModalHeader title={title} onClose={props.onClose} />}
		{children}
	</Drawer>
);

export default MobileDrawer;
