/**
 * External dependencies
 */
import { countries } from 'countries-list';
import { FC, ReactNode } from 'react';
import { SelectProps } from 'antd';
import ReactCountryFlag from 'react-country-flag';

/**
 * Internal dependencies
 */
import Select from '../Select';
import classes from './CountrySelect.module.scss';

const options = Object.entries(countries).map(([code, { name }]) => ({
	value: code,
	label: name,
}));

const render = (code: string, label: ReactNode) => (
	<>
		{typeof code === 'string' && (
			<ReactCountryFlag className={classes.flag} countryCode={code} />
		)}
		{label}
	</>
);

const CountrySelect: FC<SelectProps> = (props) => (
	<Select
		options={options}
		labelRender={(option) => render(option.value as string, option.label)}
		optionRender={(option) => render(option.value as string, option.label)}
		{...props}
	/>
);

export default CountrySelect;
