/**
 * Exteral dependencies
 */
import { useCallback } from 'react';

/**
 * Internal dependencies
 */
import {
	useAuthState,
	useAuthStateWatcher,
	useLogin,
	useLoginWithProvider,
	useRegister,
	useTokenRefreshHandler,
} from '..';
import { usePocketBase } from '@/pocketbase';

const useAuthUtils = () => {
	const pb = usePocketBase();

	useAuthStateWatcher();

	return {
		...useAuthState(),
		login: useLogin(),
		loginWithProvider: useLoginWithProvider(),
		logout: useCallback(() => pb.authStore.clear(), [pb]),
		refreshSession: useTokenRefreshHandler(),
		register: useRegister(),
	};
};

export default useAuthUtils;
