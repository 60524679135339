/**
 * Internal dependencies
 */
import { Route } from '@/router';
import { UserModel } from '../types';

const getUserProfileUrl = (user: UserModel | string) =>
	Route.UsersProfile.replace(
		':username',
		typeof user === 'object' ? user.username : user
	);

export default getUserProfileUrl;
