/**
 * External dependencies
 */
import { combineReducers } from 'redux';

/**
 * Internal dependencies
 */
import error from './error';
import loading from './loading';
import referrer from './referrer';
import remember from './remember';
import token from './token';
import user from './user';

const reducer = combineReducers({
	error,
	loading,
	referrer,
	remember,
	token,
	user,
});

export default reducer;
