/**
 * External dependencies
 */
import { useEffect } from 'react';

const useWindowEvent = <K extends keyof WindowEventMap>(
	type: K,
	listener: (this: Window, ev: WindowEventMap[K]) => unknown,
	options?: boolean | AddEventListenerOptions
) => {
	useEffect(() => {
		window.addEventListener(type, listener, options);

		return () => window.removeEventListener(type, listener);
	}, [listener, options, type]);
};

export default useWindowEvent;
