/**
 * External dependencies
 */
import { Divider } from 'antd';
import { FC } from 'react';

/**
 * Internal dependencies
 */
import {
	AccountSettingsForm,
	Greeting,
	ShareForm,
	useAccountSettingsFormController,
	useCurrentUser,
	useHandleAvatarUpload,
	useShareFormController,
} from '@/user';
import { SidebarLayout } from '@/layouts';
import { Title } from '@/components';
import classes from './AccountSettingsPage.module.scss';

const AccountSettingsPage: FC = () => (
	<SidebarLayout>
		<div className={classes.content}>
			<Title level={2} className={classes.title}>
				<Greeting size="large">{useCurrentUser()!.name}</Greeting>
			</Title>
			<AccountSettingsForm
				{...useAccountSettingsFormController()}
				handleAvatarUpload={useHandleAvatarUpload()}
				user={useCurrentUser()!}
			/>
			<Divider className={classes.divider} />
			<ShareForm
				formContentClassName={classes.shareform}
				{...useShareFormController()}
			/>
		</div>
	</SidebarLayout>
);

export default AccountSettingsPage;
