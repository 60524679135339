/**
 * External dependencies
 */
import { createContext, useContext } from 'react';
import { PocketBaseTS } from 'pocketbase-ts';
import { Schema } from '@/types';

/**
 * Internal dependencies
 */

export const PocketBaseContext = createContext<PocketBaseTS<Schema> | null>(
	null
);

export const usePocketBase = () => {
	const pb = useContext(PocketBaseContext);

	if (!pb) {
		throw new Error(
			'usePocketBase must be used within a PocketBaseProvider'
		);
	}

	return pb;
};
