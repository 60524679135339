/**
 * Exteral dependencies
 */
import { useCallback } from 'react';

/**
 * Internal dependencies
 */
import { setAuthError, setAuthLoading, useDispatch } from '@/store';

const useHandleAuthAction = () => {
	const dispatch = useDispatch();

	return useCallback(
		async <R>(action: () => Promise<R>) => {
			dispatch(setAuthLoading(true));
			dispatch(setAuthError(null));

			try {
				const result = await action();

				dispatch(setAuthLoading(false));

				return result;
			} catch (error) {
				dispatch(setAuthLoading(false));
				dispatch(setAuthError('Invalid email or password'));

				return false;
			}
		},
		[dispatch]
	);
};

export default useHandleAuthAction;
