/**
 * External dependencies
 */
import { FC } from 'react';
import { format } from 'date-fns';

/**
 * Internal dependencies
 */
import {
	AnswerForm,
	EventDetails,
	EventModel,
	EventResponseModel,
	EventResponses,
} from '@/event';
import { AnswerFormProps } from '../AnswerForm/AnswerForm';
import { UserHeader } from '@/user';
import classes from './EventPageContent.module.scss';

type EventPageContentProps = {
	answerFormProps: AnswerFormProps;
	className?: string;
	event: EventModel;
	onReact?: (response: EventResponseModel, reaction: string) => void;
	responses: EventResponseModel[];
};

const EventPageContent: FC<EventPageContentProps> = ({
	answerFormProps,
	className,
	event: { author, createdAt, instruction, isClosed, subject },
	...responsesProps
}) => (
	<div className={className}>
		{author && (
			<UserHeader
				avatarBorder="secondary"
				className={classes.author}
				size="large"
				subheading={format(createdAt, 'dd.MM.yyyy, HH:mm')}
				user={author}
			/>
		)}
		<EventDetails description={instruction} title={subject} />
		{!isClosed && (
			<p className={classes.message}>
				*** All answers will be visible after the event ends ***
			</p>
		)}
		<EventResponses className={classes.responses} {...responsesProps} />
		{!isClosed && (
			<AnswerForm rootClassName={classes.footer} {...answerFormProps} />
		)}
	</div>
);

export default EventPageContent;
