/**
 * External dependencies
 */
import { FC } from 'react';
import Countdown, { CountdownProps } from 'react-countdown';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import classes from './DateCountdown.module.scss';
import Item from './Item';

type DateCountrdownProps = {
	className?: string;
} & CountdownProps;

const cx = classNames.bind(classes);

const DateCountdown: FC<DateCountrdownProps> = ({ className, ...props }) => (
	<Countdown
		{...props}
		renderer={({ days, hours, minutes, seconds }) => (
			<div className={cx(className, 'wrap')}>
				<Item label="Days" value={days} />
				<span className={cx('colon')}>:</span>
				<Item label="Hours" value={hours} />
				<span className={cx('colon')}>:</span>
				<Item label="Minutes" value={minutes} />
				<span className={cx('colon')}>:</span>
				<Item label="Seconds" value={seconds} />
			</div>
		)}
	/>
);

export default DateCountdown;
