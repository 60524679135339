/**
 * External dependencies
 */
import { FC, PropsWithChildren } from 'react';

/**
 * Internal dependencies
 */
import { Header } from '@/components';
import { useAuth } from '@/auth';
import { useHandleAvatarUpload, useCurrentUser } from '@/user';

const AppLayout: FC<PropsWithChildren> = ({ children }) => {
	const user = useCurrentUser();

	return (
		<>
			<Header
				handleAvatarUpload={useHandleAvatarUpload()}
				onLogOut={useAuth().logout}
				user={user ?? undefined}
			/>
			{children}
		</>
	);
};

export default AppLayout;
