/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { AccountActionsProps } from '../AccountActions/AccountActions';
import { useMatchBreakpoint } from '@/hooks';
import { AvatarUploadHandlerProps, UserData } from '@/user';
import UserBarDesktop from './Desktop';
import UserBarMobile from './Mobile';

export type UserBarProps = AccountActionsProps &
	AvatarUploadHandlerProps & {
		user: UserData;
	};

const UserBar: FC<UserBarProps> = (props) =>
	useMatchBreakpoint('md') ? (
		<UserBarMobile {...props} />
	) : (
		<UserBarDesktop {...props} />
	);

export default UserBar;
