/**
 * Internal dependencies
 */
import { createAction } from '../action-creators';
import { EventActivityModel, EventModel, EventResponseModel } from '@/event';
import {
	REMOVE_EVENT_RESPONSE,
	SET_CURRENT_EVENT,
	SET_EVENT_ACTIVITIES,
	SET_EVENT_RESPONSES,
	SET_EVENT_RESPONSES_ERROR,
	SET_EVENT_RESPONSES_LOADING,
	UPSET_EVENT_RESPONSE,
} from '../actionTypes';

export const setCurrentEvent = createAction<string | EventModel | null>(
	SET_CURRENT_EVENT
);

export const setEventResponses =
	createAction<EventResponseModel[]>(SET_EVENT_RESPONSES);

export const setEventResponsesLoading = createAction<boolean>(
	SET_EVENT_RESPONSES_LOADING
);

export const setEventResponsesError = createAction<boolean>(
	SET_EVENT_RESPONSES_ERROR
);

export const upsetEventResponse =
	createAction<EventResponseModel>(UPSET_EVENT_RESPONSE);

export const removeEventResponse = createAction<EventResponseModel | string>(
	REMOVE_EVENT_RESPONSE
);

export const setEventActivities = createAction<EventActivityModel[] | null>(
	SET_EVENT_ACTIVITIES
);
