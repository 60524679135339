/**
 * Internal dependencies
 */
import type { RootState } from './types';

const initialState: RootState = {
	auth: {
		error: null,
		loading: false,
		referrer: null,
		remember: false,
		token: null,
		user: null,
	},
	currentEvent: {
		activities: null,
		error: null,
		id: null,
		loading: false,
		responses: null,
	},
	events: {
		data: null,
		error: null,
		loading: false,
	},
	faq: {
		items: null,
	},
	invitation: {
		data: null,
		error: null,
		loading: false,
	},
	router: {
		currentRoute: null,
		prevRoute: null,
	},
	ui: {
		headerHeight: 0,
	},
};

export default initialState;
