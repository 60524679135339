/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { SidebarLayout } from '@/layouts';
import { HomepageContent, useHomepageController } from '@/homepage';

const HomePage: FC = () => (
	<SidebarLayout>
		<HomepageContent {...useHomepageController()} />
	</SidebarLayout>
);

export default HomePage;
