/**
 * External dependencies
 */
import { differenceInMilliseconds, isFuture, isPast } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { Button, WhiteBox } from '@/components';
import { EventModel, EventStatus, getEventUrl, Participants } from '@/event';
import { ReactComponent as ChevronRight } from '@/images/chevron-right-large.svg';
import { getUserProfileUrl, UserCountPill, UserHeader } from '@/user';
import classes from './EventBox.module.scss';

type EventBoxProps = {
	actionLabel?: string;
	event: EventModel;
	onActionClick?: () => void;
};

const cx = classNames.bind(classes);

const EventBox: FC<EventBoxProps> = ({
	actionLabel = 'See answers',
	event,
	onActionClick,
}) => {
	const {
		attendees,
		author,
		endsAt,
		id,
		instruction,
		responsesCount,
		subject,
	} = event;

	const [isClosed, setIsClosed] = useState(isPast(endsAt));

	useEffect(() => {
		const diff = isFuture(endsAt)
			? differenceInMilliseconds(endsAt, new Date())
			: 0;

		if (diff > 0) {
			setTimeout(() => setIsClosed(true), diff);
		}
	}, [endsAt, setIsClosed]);

	return (
		<WhiteBox className={cx('wrap', { 'is-closed': isClosed })}>
			<div className={cx('details')}>
				{author && (
					<UserHeader
						avatarBorder="secondary"
						className={cx('header')}
						heading={subject}
						size="large"
						subheading={
							<Link to={getUserProfileUrl(author.username)}>
								{author.name}
							</Link>
						}
						user={author}
					>
						<UserCountPill
							current={responsesCount ?? 0}
							max={attendees.length}
						/>
					</UserHeader>
				)}
				<p className={cx('content')}>{instruction}</p>
				<Participants
					className={cx('attendees')}
					participants={attendees}
				/>
			</div>
			<EventStatus
				className={cx('actions')}
				event={event}
				footer={
					<Button
						className={cx('action-button')}
						icon={<ChevronRight />}
						size="large"
						href={onActionClick ? undefined : getEventUrl(id)}
						onClick={onActionClick}
					>
						{actionLabel}
					</Button>
				}
			/>
		</WhiteBox>
	);
};

export default EventBox;
