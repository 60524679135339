/**
 * External dependencies
 */
import { useEffect } from 'react';

/**
 * Internal dependencies
 */
import {
	removeEvent,
	setEvents,
	setEventsError,
	setEventsLoading,
	upsetEvent,
	useDispatch,
} from '@/store';
import { getEventModel } from '@/event/utils';
import { usePocketBase } from '@/pocketbase';

const useEventsSync = () => {
	const pb = usePocketBase();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setEventsLoading(true));

		const baseCollection = pb.collection('events');
		const listCollection = pb.collection('events_list');

		listCollection
			.getFullList({
				expand: [{ key: 'author' }, { key: 'attendees' }],
				sort: '-createdAt',
			})
			.then((events) =>
				dispatch(setEvents(events.map((event) => getEventModel(event))))
			)
			.catch(() => dispatch(setEventsError(true)))
			.finally(() => dispatch(setEventsLoading(false)));

		const subscribeTopic = '*';

		baseCollection.subscribe(
			subscribeTopic,
			({ action, record }) => {
				if (action === 'delete') {
					dispatch(removeEvent(record.id));
					return;
				}

				listCollection
					.getOne(record.id, {
						expand: [{ key: 'author' }, { key: 'attendees' }],
					})
					.then((event) => dispatch(upsetEvent(getEventModel(event))))
					.catch(() => dispatch(setEventsError(true)));
			},
			{
				expand: [{ key: 'author' }, { key: 'attendees' }],
			}
		);

		return () => {
			baseCollection.unsubscribe(subscribeTopic);
		};
	}, [dispatch, pb]);
};

export default useEventsSync;
