/**
 * External dependencies
 */
import { FC } from 'react';
import { Flex } from 'antd';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import classes from './ParticipantsCount.module.scss';
import { ReactComponent as UsersIcon } from '@/images/users.svg';
import { Text } from '@/components';

const cx = classNames.bind(classes);

type ParticipantsCountProps = {
	count: number;
};

const ParticipantsCount: FC<ParticipantsCountProps> = ({ count }) => (
	<Flex className={cx('wrap')} gap={4} align="center">
		<UsersIcon />
		<Text className={cx('count')}>{count}</Text>
		<Text className={cx('label')}>
			{count === 1 ? 'Person' : 'People'} joined
		</Text>
	</Flex>
);

export default ParticipantsCount;
