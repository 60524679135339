/**
 * External dependencies
 */
import { createBrowserRouter, RouteObject } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { Route, RouteConfig, Routes } from './types';
import { routes } from './config';

const isRouteConfig = (value: Routes[keyof Routes]): value is RouteConfig =>
	!!value && typeof value === 'object' && 'childRoutes' in value;

export const prepareRoutes = (routes: Routes): RouteObject[] =>
	Object.entries(routes).map(([path, config]) => ({
		path,
		Component: isRouteConfig(config) ? config.layout : config,
		...(isRouteConfig(config)
			? { children: prepareRoutes(config.childRoutes) }
			: undefined),
	}));

export const createRouter = () => createBrowserRouter(prepareRoutes(routes));

export const isRoute = (route: string): route is Route =>
	Object.values(Route).includes(route as Route);
