/**
 * External dependencies
 */
import { FC } from 'react';
import { List } from 'antd';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import classes from './EventTips.module.scss';
import { eventTips } from '@/config';
import { Button } from '@/components';

const cx = classNames.bind(classes);

type EventTipsProps = {
	onMoreClick?: () => void;
};

const EventTips: FC<EventTipsProps> = ({ onMoreClick }) => (
	<List
		className={cx('wrap')}
		dataSource={eventTips}
		itemLayout="horizontal"
		renderItem={(item, index) => (
			<List.Item className={cx('item')}>
				{index + 1}. {item}
			</List.Item>
		)}
		footer={
			<div className={cx('footer')}>
				<p className={cx('footer-header')}>
					You feel lost or lacking inspiration?
				</p>
				<p>
					More tips on creating a successful event you can find here:
					<Button
						className={cx('modal-button')}
						variant="link"
						color="secondary"
						onClick={onMoreClick}
					>
						Explore more tips
					</Button>
				</p>
			</div>
		}
	/>
);

export default EventTips;
