/**
 * External dependencies
 */
import { combineReducers } from 'redux';

/**
 * Internal dependencies
 */
import activities from './activities';
import error from './error';
import id from './id';
import loading from './loading';
import responses from './responses';

const reducer = combineReducers({
	activities,
	error,
	id,
	loading,
	responses,
});

export default reducer;
