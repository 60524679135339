/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import classes from '../DateCountdown.module.scss';

type ItemProps = {
	label: string;
	value: number;
};

const Item: FC<ItemProps> = ({ label, value }) => (
	<>
		<span className={classes.label}>{label}</span>
		<span className={classes.value}>{value}</span>
	</>
);

export default Item;
