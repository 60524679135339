/**
 * External dependencies
 */
import { FC } from 'react';
import { Form, FormItemProps } from 'antd';

/**
 * Internal dependencies
 */
import PasswordInput from '../PasswordInput';

type PasswordConfirmFormItemProps = FormItemProps & {
	baseFieldName?: string;
};

const PasswordConfirmFormItem: FC<PasswordConfirmFormItemProps> = ({
	baseFieldName = 'password',
	label = 'Confirm Password',
	name = `${baseFieldName}Confirm`,
	rules = [],
	...props
}) => (
	<Form.Item
		dependencies={[baseFieldName]}
		rules={[
			...rules,
			{
				required: true,
				message: 'Please confirm your password.',
			},
			({ getFieldValue }) => ({
				validator: (_, value) =>
					!value || getFieldValue(baseFieldName) === value
						? Promise.resolve()
						: Promise.reject(
								new Error('The password does not match.')
							),
			}),
		]}
		{...{ label, name, ...props }}
	>
		<PasswordInput />
	</Form.Item>
);

export default PasswordConfirmFormItem;
