/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { SidebarLayout } from '@/layouts';
import { EventsList, useJoinedEvents } from '@/event';

const MyEventsPage: FC = () => (
	<SidebarLayout>
		<EventsList
			events={useJoinedEvents()}
			noEventsLabel="You have not joined to any event."
			title={['My  ', 'events:']}
		/>
	</SidebarLayout>
);

export default MyEventsPage;
