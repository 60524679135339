/**
 * External dependencies
 */
import { FC, ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { DateCountdown, DateProgress } from '@/components';
import classes from './EventStatus.module.scss';
import { EventModel } from '@/event/types';
import { differenceInMilliseconds, isFuture, isPast } from 'date-fns';
import { Divider } from 'antd';

const cx = classNames.bind(classes);

type EventStatusProps = {
	className?: string;
	event: EventModel;
	footer?: ReactNode;
	progressFirst?: boolean;
	titleDivider?: boolean;
};

const EventStatus: FC<EventStatusProps> = ({
	className,
	event,
	footer,
	progressFirst,
	titleDivider,
}) => {
	const [isClosed, setIsClosed] = useState(isPast(event.endsAt));

	useEffect(() => {
		const diff = isFuture(event.endsAt)
			? differenceInMilliseconds(event.endsAt, new Date())
			: 0;

		if (diff > 0) {
			setTimeout(() => setIsClosed(true), diff);
		}
	}, [event, setIsClosed]);

	const progress = (
		<DateProgress
			className={cx('progress')}
			from={event.createdAt}
			to={event.endsAt}
		/>
	);
	return (
		<div className={cx('wrap', className)}>
			<p className={cx('title')}>
				{isClosed ? 'Ended' : 'Time Remaining:'}
			</p>
			{titleDivider && <Divider className={cx('divider')} />}
			{progressFirst && progress}
			{!isClosed && (
				<DateCountdown
					className={cx('countdown')}
					date={event.endsAt}
				/>
			)}
			{!progressFirst && progress}
			{footer}
		</div>
	);
};

export default EventStatus;
