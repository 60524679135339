/**
 * External dependencies
 */
import { FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import classes from '../EventTipsModal.module.scss';
import { ReactComponent as IconLightbulb } from '@/images/icon-lightbulb.svg';

type HintProps = {
	hint: string;
};

const cx = classNames.bind(classes);

const Hint: FC<HintProps> = ({ hint }) => (
	<div className={cx('hint')}>
		<div className={cx('hint-header')}>
			<span className={cx('hint-icon')}>
				<IconLightbulb />
			</span>
			Tip:
		</div>
		<p className={cx('hint-content')}>{hint}</p>
	</div>
);

export default Hint;
