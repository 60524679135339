/**
 * External dependencies
 */
import { FC } from 'react';
import classNames from 'classnames/bind';
import Text from 'antd/lib/typography/Text';

/**
 * Internal dependencies
 */
import classes from './Logo.module.scss';

const cx = classNames.bind(classes);

const Logo: FC = () => (
	<Text className={cx('logo', 'font-dela-gothic-one')}>Event Swirl</Text>
);

export default Logo;
