/**
 * External dependencies
 */
import { Form } from 'antd';
import { useEffect } from 'react';

/**
 * Internal dependencies
 */
import {
	AccountSettingsFormFields,
	profileFields,
	UserUpdateData,
	useUpdateUser,
} from '@/user';
import { getFieldErrorMessage } from '@/error/utils';
import { FormProps } from '@/forms';

type UseAccountSettingsFormControllerArgs = {
	onSuccess?: () => void;
	prepareData?: (
		data: UserUpdateData,
		fields: AccountSettingsFormFields
	) => UserUpdateData;
};

type UseAccountSettingsFormControllerReturn = Pick<
	FormProps<AccountSettingsFormFields>,
	'onFinish' | 'processing' | 'error' | 'form'
>;

const errorMessages: Record<string, string> = {
	validation_not_unique: 'This username is already taken.',
};

const useAccountSettingsFormController = ({
	onSuccess,
	prepareData = (data) => data,
}: UseAccountSettingsFormControllerArgs = {}): UseAccountSettingsFormControllerReturn => {
	const [form] = Form.useForm<AccountSettingsFormFields>();
	const [update, { processing, error }] = useUpdateUser();

	const usernameError = getFieldErrorMessage(
		error,
		'username',
		errorMessages
	);

	useEffect(() => {
		if (usernameError) {
			form.setFields([
				{
					name: 'username',
					errors: [usernameError],
				},
			]);
		}
	}, [form, usernameError]);

	return {
		error: usernameError ? undefined : (error?.messages[0] ?? null),
		form,
		onFinish: async (fields) => {
			const {
				email,
				emailShowOnProfile,
				firstNameShowOnProfile,
				lastNameShowOnProfile,
				locationShowOnProfile,
				username,
				...data
			} = fields;

			const success = await update(
				prepareData(
					{
						...data,
						profileFields: profileFields.filter(
							(field) => fields[`${field}ShowOnProfile`] === true
						),
					},
					fields
				)
			);

			if (success) {
				onSuccess?.();
			}
		},
		processing,
	};
};

export default useAccountSettingsFormController;
