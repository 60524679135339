/**
 * External dependencies
 */
import { useCallback, useMemo } from 'react';

/**
 * Internal dependencies
 */
import { EventResponseModel } from '@/event/types';
import { usePocketBase } from '@/pocketbase';

const useHandleReaction = () => {
	const pb = usePocketBase();
	const collection = useMemo(() => pb.collection('event_reactions'), [pb]);

	return useCallback(
		(response: EventResponseModel, reaction: string) => {
			if (!response.currentUserReaction) {
				collection.create({
					author: pb.authStore.record?.id,
					response: response.id,
					reaction,
				});

				return;
			}

			if (response.currentUserReaction.reaction === reaction) {
				collection.delete(response.currentUserReaction.id);
				return;
			}

			collection.update(response.currentUserReaction.id, {
				reaction,
			});
		},
		[collection, pb]
	);
};

export default useHandleReaction;
