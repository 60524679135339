/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { useEventsSync } from '@/event/hooks';

type EventsSyncProps = {};

const EventsSync: FC<EventsSyncProps> = () => {
	useEventsSync();

	return null;
};

export default EventsSync;
