/**
 * External dependencies
 */
import { FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { isAudio, isImage, isVideo } from '@/utils';
import classes from './EventMedia.module.scss';

type EventMediaProps = {
	className?: string;
	media: string[];
};

const cx = classNames.bind(classes);

const EventMedia: FC<EventMediaProps> = ({ className, media }) => (
	<div className={cx('wrap', className)}>
		{media.map((src) =>
			isImage(src) ? (
				<img
					alt=""
					className={cx('image')}
					height={0}
					key={src}
					src={src}
					width={0}
				/>
			) : isAudio(src) ? (
				<audio className={cx('audio')} controls key={src} src={src} />
			) : isVideo(src) ? (
				<video className={cx('video')} controls key={src} src={src} />
			) : null
		)}
	</div>
);

export default EventMedia;
