/**
 * External dependencies
 */
import { FC } from 'react';
import { Input } from 'antd';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { AvatarUploadHandlerProps, UserData } from '@/user/types';
import { Form } from '@/forms';
import { ReactComponent as AtIcon } from '@/images/icon-at.svg';
import classes from './BaseUserFields.module.scss';
import EditableAvatar from '../EditableAvatar';

const cx = classNames.bind(classes);

type BaseUserFieldsProps = AvatarUploadHandlerProps & {
	className?: string;
	disabled?: boolean;
	user: UserData;
};

const BaseUserFields: FC<BaseUserFieldsProps> = ({
	className,
	disabled,
	handleAvatarUpload,
	user,
}) => (
	<div className={cx('wrap', className)}>
		<EditableAvatar
			className={classes.avatar}
			handleUpload={handleAvatarUpload}
			src={user.avatar?.length ? user.avatar : undefined}
		/>
		<Form.Item
			className={classes.item}
			label="Your Username"
			name="username"
			rules={[
				{
					required: true,
				},
			]}
		>
			<Input prefix={<AtIcon />} size="large" disabled={disabled} />
		</Form.Item>
	</div>
);

export default BaseUserFields;
