/**
 * External dependencies
 */
import { useCallback, useState } from 'react';

/**
 * Internal dependencies
 */
import { useError } from '@/error';
import { usePocketBase } from '@/pocketbase';

type PocketBaseCallCallback = (
	pb: ReturnType<typeof usePocketBase>
) => Promise<any>;

const usePocketBaseCall = () => {
	const [error, setError] = useError();
	const [processing, setProcessing] = useState(false);

	const pb = usePocketBase();

	return {
		error,
		processing,
		pocketBaseCall: useCallback(
			async (callback: PocketBaseCallCallback) => {
				try {
					setProcessing(true);
					setError(null);

					await callback(pb);

					setProcessing(false);

					return true;
				} catch (error) {
					setProcessing(false);
					setError(error);

					return false;
				}
			},
			[pb, setError]
		),
	};
};

export default usePocketBaseCall;
