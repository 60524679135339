/**
 * External dependencies
 */
import { AvatarProps as BaseAvatarProps, Avatar as BaseAvatar } from 'antd';
import { FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { avatarSize, avatarSizeMobile } from '@/config';
import classes from './Avatar.module.scss';
import PlaceholderImage from '../PlaceholderImage';

const cx = classNames.bind(classes);

type AvatarProps = BaseAvatarProps & {
	border?: 'large' | 'small' | boolean;
	color?: 'primary' | 'secondary';
	placeholderScale?: number;
};

const Avatar: FC<AvatarProps> = ({
	border,
	className,
	color,
	placeholderScale = 2,
	size,
	src,
	...props
}) => (
	<BaseAvatar
		{...props}
		className={cx('avatar', className, {
			border,
			[`border-${border}`]: typeof border === 'string',
			[`color-${color}`]: color,
		})}
		size={
			typeof size === 'string'
				? avatarSizeMobile[size]
					? {
							xs: avatarSizeMobile[size],
							sm: avatarSize[size],
							md: avatarSize[size],
							lg: avatarSize[size],
							xl: avatarSize[size],
							xxl: avatarSize[size],
						}
					: avatarSize[size]
				: size
		}
		src={
			src ??
			(!props.icon && !props.children && (
				<PlaceholderImage
					size={
						typeof size === 'number'
							? size
							: typeof size === 'string'
								? avatarSize[size]
								: avatarSize.default
					}
					style={
						placeholderScale
							? { scale: `${placeholderScale * 100}%` }
							: undefined
					}
				/>
			))
		}
	/>
);

export default Avatar;
