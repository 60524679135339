/**
 * Internal dependencies
 */
import { createAction } from '../action-creators';
import { RootState } from '../types';
import {
	REMOVE_EVENT,
	SET_EVENTS,
	SET_EVENTS_ERROR,
	SET_EVENTS_LOADING,
	UPSET_EVENT,
} from '../actionTypes';
import { EventModel } from '@/event';

export const setEventsError =
	createAction<RootState['events']['error']>(SET_EVENTS_ERROR);

export const setEvents = createAction<RootState['events']['data']>(SET_EVENTS);

export const setEventsLoading =
	createAction<RootState['events']['loading']>(SET_EVENTS_LOADING);

export const upsetEvent = createAction<EventModel>(UPSET_EVENT);

export const removeEvent = createAction<string | EventModel>(REMOVE_EVENT);
