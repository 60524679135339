/**
 * External dependencies
 */
import { ButtonProps } from 'antd';
import { PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { Column, Text } from '@/components';
import { FormProps } from '@/forms/types';
import classes from './TitledForm.module.scss';
import Form from '../Form';
import FormHeader, { FormHeaderProps } from '../FormHeader/FormHeader';
import SubmitButton from '../SubmitButton';

const cx = classNames.bind(classes);

export type TitledFormProps<V extends {} = {}> = Omit<
	FormProps<V>,
	'title' | 'children'
> &
	FormHeaderProps &
	PropsWithChildren<{
		beforeForm?: ReactNode;
		buttonIcon?: ReactNode;
		buttonLabel?: string;
		buttonVariant?: ButtonProps['variant'];
		centered?: boolean;
		footer?: ReactNode;
		formContentClassName?: string;
		hasButtonArrow?: boolean;
	}>;

const TitledForm = <V extends {}>({
	beforeForm,
	buttonIcon,
	buttonLabel = 'Submit',
	buttonVariant,
	centered = true,
	children,
	disabled,
	footer,
	formContentClassName,
	hasButtonArrow,
	processing,
	subtitle,
	title,
	...props
}: TitledFormProps<V>): ReactNode => (
	<div className={cx('wrap')}>
		<FormHeader
			className={cx('header')}
			{...{ centered, subtitle, title }}
		/>
		{beforeForm}
		<Form {...props} disabled={disabled || processing} layout="vertical">
			<div className={formContentClassName}>{children}</div>
			<Column className={cx('footer', { centered })} gap={10}>
				<SubmitButton
					className={cx('button')}
					hasArrow={hasButtonArrow}
					icon={buttonIcon}
					loading={processing}
					variant={buttonVariant}
				>
					{buttonLabel}
				</SubmitButton>
				{footer && <Text className={cx('footer-text')}>{footer}</Text>}
			</Column>
		</Form>
	</div>
);

export default TitledForm;
