/**
 * Internal dependencies
 */
import { createAction } from '../action-creators';
import { RootState } from '../types';
import {
	SET_INVITATION_DATA,
	SET_INVITATION_ERROR,
	SET_INVITATION_LOADING,
} from '../actionTypes';

export const setInvitationError =
	createAction<RootState['invitation']['error']>(SET_INVITATION_ERROR);

export const setInvitationData =
	createAction<RootState['invitation']['data']>(SET_INVITATION_DATA);

export const setInvitationLoading = createAction<
	RootState['invitation']['loading']
>(SET_INVITATION_LOADING);
