/**
 * External dependencies
 */
import { isPast } from 'date-fns';

/**
 * Internal dependencies
 */
import { useEvents } from '@/event';

const useClosedEvents = () =>
	useEvents().events?.filter((event) => isPast(event.endsAt)) ?? [];

export default useClosedEvents;
