/**
 * External dependencies
 */
import { FC } from 'react';
import { Modal, ModalProps } from 'antd';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { eventMoreTips } from '@/config';
import classes from './EventTipsModal.module.scss';
import Examples from './Examples';
import Hint from './Hint';

type EventTipsModalProps = ModalProps;

const cx = classNames.bind(classes);

const EventTipsModal: FC<EventTipsModalProps> = (props) => (
	<Modal
		className={cx('modal')}
		classNames={{ mask: cx('modal-mask') }}
		wrapClassName={cx('modal-wrapper')}
		footer={null}
		{...props}
	>
		<p className={cx('title')}>More inspirational tips</p>
		<div className={cx('content')}>
			{eventMoreTips.map(({ header, content, examples, hint }, index) => (
				<div className={cx('tip', { 'with-hint': !!hint })} key={index}>
					<p
						className={cx('tip-header')}
					>{`${index + 1}. ${header}`}</p>
					{content && <p className={cx('tip-content')}>{content}</p>}
					{hint && <Hint hint={hint} />}
					{examples && (
						<>
							<p className={cx('examples-header')}>Example:</p>
							<Examples examples={examples} />
						</>
					)}
				</div>
			))}
		</div>
	</Modal>
);

export default EventTipsModal;
