/**
 * External dependencies
 */
import { useEffect } from 'react';
import { useLocation } from 'react-router';

/**
 * Internal dependencies
 */
import { Route } from '@/router/types';
import { setRoutes, useDispatch, useSelector } from '@/store';
import { useValueRef } from '@/hooks';
import useIsPublicRoute from '../useIsPublicRoute';

const useRouteWatcher = () => {
	const { isPublicRoute } = useIsPublicRoute();
	const dispatch = useDispatch();
	const path = useLocation().pathname;
	const routesRef = useValueRef(useSelector(({ router }) => router));

	useEffect(() => {
		const { currentRoute, prevRoute } = routesRef.current;

		dispatch(
			setRoutes({
				current: path as Route,
				prev:
					currentRoute &&
					!isPublicRoute(currentRoute) &&
					currentRoute !== path
						? currentRoute
						: prevRoute,
			})
		);
	}, [dispatch, isPublicRoute, path]);
};

export default useRouteWatcher;
