/**
 * External dependencies
 */
import { dash } from 'radash';
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { ContactForm } from '@/support';
import { ContactFormProps } from '../ContactForm/ContactForm';
import { Faq } from '@/types';
import { Title } from '@/components';
import classes from './SupportContent.module.scss';

type SupportContentProps = {
	items: Faq[];
} & ContactFormProps;

const SupportContent: FC<SupportContentProps> = ({
	items,
	...contactFormProps
}) => (
	<div className={classes.wrap}>
		<div className={classes.content}>
			{items.map(({ id, title, content }) => (
				<div key={id} className={classes.item}>
					<span id={dash(title)} className={classes['item-anchor']} />
					<Title className={classes['item-title']} level={2}>
						{title}
					</Title>
					<div
						className={classes['item-content']}
						dangerouslySetInnerHTML={{ __html: content }}
					/>
				</div>
			))}
			<div className={classes.item}>
				<Title className={classes['item-title']} level={2}>
					Need more help?
				</Title>
				<p className={classes['item-subtitle']}>
					Have you any comments? Send us an email:
				</p>
				<ContactForm {...contactFormProps} />
			</div>
		</div>
		<div className={classes.toc}>
			{items.map(({ id, title }) => (
				<a
					key={id}
					className={classes['toc-item']}
					href={`#${dash(title)}`}
				>
					{title}
				</a>
			))}
		</div>
	</div>
);

export default SupportContent;
