/**
 * External dependencies
 */
import { FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import classes from '../EventTipsModal.module.scss';

type ExamplesProps = {
	examples: { type: string; header?: string; content: string }[];
};

const cx = classNames.bind(classes);

const Examples: FC<ExamplesProps> = ({ examples }) => (
	<div className={cx('examples-list')}>
		{examples.map(({ type, header, content }) => (
			<div key={type} className={cx('example', `example-${type}`)}>
				{header && <p className={cx('example-header')}>{header}</p>}
				<p className={cx('example-content')}>{content}</p>
			</div>
		))}
	</div>
);

export default Examples;
