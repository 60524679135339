export const reactionEmojis = ['👍', '😍', '❤️'];

export const eventTips = [
	'Set up instructions for an event (up to 250 characters)',
	'Share it with others.',
	'Anticipate their response!',
];

export const eventMoreTips = [
	{
		header: 'Start with a simple idea or action.',
		content:
			'Think of an everyday activity or a unique concept. Examples: pouring water, making sound, touching object, imagine a cloud.',
	},
	{
		header: 'Keep it minimal.',
		hint: 'Avoid overly detailed instruction, let the performer fill in the gaps.',
		content:
			'Your instruction should be concise, but it should leave space for interpretation and creative execution.',
		examples: [
			{
				type: 'bad',
				header: 'Too detailed:',
				content:
					'Pour 100 ml of water to a tall glass and stir it three times with a spoon.',
			},
			{
				type: 'good',
				header: 'Better:',
				content: 'Pour water into a glass and stir.',
			},
		],
	},
	{
		header: 'Use clear, accessible language.',
		content:
			'Even when you write about abstract ideas. It should be easy to understand, with no jargon or complex theories.',
		examples: [
			{
				type: 'neutral',
				content: 'Touch the floor and listen to the sound.',
			},
		],
	},
	{
		header: 'Invite interpretation.',
		content:
			'Write instruction that allows for multiple interpretations by different people. This encourages creativity and makes each performance of the score unique.',
	},
	{
		header: 'Embrace chance and the everyday.',
		content:
			'Incorporate elements of unpredictability or the mundane to the score to connect art with everyday life.',
		examples: [
			{
				type: 'neutral',
				content: 'Watch the boiling water.',
			},
		],
	},
	{
		header: 'Focus on experience over outcome.',
		content:
			'The Event doesn’t need to result in a spectacular outcome. The experience or process of performing/doing an action is often the point, not its result. The uploaded documentation is more to see how other people approach certain task rather than being a polished work of art. Think about it more as of a reels/shorts that last 24h than a properly designed post on social media.',
	},
	{
		header: 'Share and perform it!',
	},
];
