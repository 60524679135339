/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { Progress } from '@/components';
import { useSignUpProgress } from '@/sign-up/hooks';
import classes from './SignUpProgress.module.scss';

type SignUpProgressProps = {};

const SignUpProgress: FC<SignUpProgressProps> = () => {
	const { progress, step, totalSteps } = useSignUpProgress();

	return (
		<div>
			<Progress
				className={classes.progress}
				format={() => `Step ${step}/${totalSteps}`}
				percent={progress}
				percentPosition={{ align: 'center', type: 'outer' }}
				showInfo
			/>
		</div>
	);
};

export default SignUpProgress;
