/**
 * External dependencies
 */
import { combineReducers } from 'redux';

/**
 * Internal dependencies
 */
import currentRoute from './currentRoute';
import prevRoute from './prevRoute';

const reducer = combineReducers({
	currentRoute,
	prevRoute,
});

export default reducer;
