/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { Flex } from '@/components';
import classes from './SocialLoginButtons.module.scss';
import FacebookLoginButton from '../FacebookLoginButton';
import GoogleLoginButton from '../GoogleLoginButton';

export type SocialLoginButtonsProps = {
	onClick?: (provider: 'google' | 'facebook') => void;
};

const SocialButtons: FC<SocialLoginButtonsProps> = ({ onClick }) => (
	<Flex className={classes.wrap} align="center" justify="center" wrap>
		<GoogleLoginButton onClick={() => onClick?.('google')} />
		<FacebookLoginButton onClick={() => onClick?.('facebook')} />
	</Flex>
);

export default SocialButtons;
