/**
 * External dependencies
 */
import { Upload, UploadProps } from 'antd';
import { FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { Button } from '@/components';
import { ReactComponent as IconLink } from '@/images/icon-link.svg';
import classes from './AnswerAttachments.module.scss';

const cx = classNames.bind(classes);

const AnswerAttachments: FC<UploadProps> = ({
	accept = 'audio/*,image/*,video/*',
	className,
	...props
}) => (
	<Upload
		accept={accept}
		beforeUpload={() => false}
		className={cx('wrap', 'font-inter', className)}
		listType="picture"
		{...props}
	>
		<Button
			className={cx('button')}
			color="secondary"
			shape="circle"
			size="small"
			title="Upload attachments"
		>
			<IconLink />
		</Button>
	</Upload>
);

export default AnswerAttachments;
