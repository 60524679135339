/**
 * External dependencies
 */
import { Reducer } from 'redux';

/**
 * Internal dependencies
 */
import { setAuthError } from '@/store/actions';
import initialState from '../../initialState';
import isAction from '../../isAction';

const error: Reducer<string | null> = (
	state = initialState.auth.error,
	action
) => (isAction(action, setAuthError) ? action.payload : state);

export default error;
