/**
 * External dependencies
 */
import { FC } from 'react';
import { Input as BaseInput, InputProps as BaseInputProps } from 'antd';

/**
 * Internal dependencies
 */
import { FormFieldStatus } from '@/types';
import FormFieldStatusIcon from '../FormFieldStatusIcon';

export type InputProps = {
	status?: FormFieldStatus;
} & Omit<BaseInputProps, 'status'>;

const Input: FC<InputProps> = ({ status, suffix, ...props }) => (
	<BaseInput
		// @ts-expect-error Any string works as status and addss proper classes.
		status={status}
		suffix={suffix ?? <FormFieldStatusIcon status={status} />}
		{...props}
	/>
);

export default Input;
