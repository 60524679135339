/**
 * External dependencies
 */
import { Reducer } from 'redux';

/**
 * Internal dependencies
 */
import { EventModel } from '@/event';
import { removeEvent, setEvents, upsetEvent } from '../../actions';
import initialState from '../../initialState';
import isAction from '../../isAction';

const data: Reducer<EventModel[] | null> = (
	state = initialState.events.data,
	action
) => {
	if (isAction(action, setEvents)) {
		return action.payload;
	}

	if (isAction(action, upsetEvent)) {
		if (!state) {
			return [action.payload];
		}

		const itemIndex = state?.findIndex(
			(item) => item.id === action.payload.id
		);

		if (typeof itemIndex === 'number' && itemIndex >= 0) {
			const newState = [...state];
			newState[itemIndex] = action.payload;
			return newState;
		}

		return [action.payload, ...state];
	}

	if (isAction(action, removeEvent)) {
		const itemIndex = state?.findIndex(
			(item) =>
				item.id ===
				(typeof action.payload === 'string'
					? action.payload
					: action.payload.id)
		);

		if (state && typeof itemIndex === 'number' && itemIndex >= 0) {
			const nextState = [...state];

			nextState.splice(itemIndex, 1);

			return nextState;
		}

		return state;
	}

	return state;
};

export default data;
