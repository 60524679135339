/**
 * External dependencies
 */
import { FC, ReactNode } from 'react';
import { TextAreaProps as BaseTextareaProps } from 'antd/es/input';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { FormFieldStatus } from '@/types';
import BaseTextArea from 'antd/es/input/TextArea';
import classes from './Textarea.module.scss';
import FormFieldStatusIcon from '../FormFieldStatusIcon';

export type TextareaProps = {
	prefix?: ReactNode;
	status?: FormFieldStatus;
	suffix?: ReactNode;
} & Omit<BaseTextareaProps, 'prefix' | 'status'>;

const cx = classNames.bind(classes);

const Textarea: FC<TextareaProps> = ({
	className,
	prefix,
	suffix,
	status,
	...props
}) => (
	<div
		className={cx('wrapper', {
			'with-prefix': !!prefix,
			'with-suffix': !!suffix || status,
			success: status === 'success',
			error: status === 'error',
			disabled: props.disabled,
		})}
	>
		{!!prefix && <span className={classes['prefix']}>{prefix}</span>}
		<BaseTextArea className={cx('textarea', className)} {...props} />
		{(!!suffix || status) && (
			<span className={classes['suffix']}>
				{suffix ?? (
					<FormFieldStatusIcon
						status={status}
						className={classes.statusIcon}
					/>
				)}
			</span>
		)}
	</div>
);

export default Textarea;
