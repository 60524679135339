/**
 * External dependencies
 */
import { FC } from 'react';
import { intlFormatDistance } from 'date-fns';
import { Link } from 'react-router-dom';
import { List } from 'antd';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { EventActivityProps } from './types';
import { getUserProfileUrl, UserHeader } from '@/user';
import classes from './EventActivity.module.scss';

const cx = classNames.bind(classes);

const EventActivity: FC<EventActivityProps> = ({ activities, loading }) => (
	<List
		dataSource={activities}
		itemLayout="horizontal"
		loading={loading}
		locale={{ emptyText: 'No activity yet' }}
		renderItem={({ createdAt, user, action, author, message }) => (
			<List.Item className={cx('activity')}>
				<UserHeader
					user={user}
					heading={
						<span className={cx('activity-heading')}>
							<Link to={getUserProfileUrl(user.username)}>
								<strong>{user.name}</strong>
							</Link>{' '}
							{action === 'reaction' && (
								<>
									reacted on the answer of{' '}
									<Link
										to={getUserProfileUrl(author.username)}
									>
										<strong>{author.name}</strong>
									</Link>
								</>
							)}
							{action === 'response' && message}
						</span>
					}
					size="small"
					subheading={intlFormatDistance(createdAt, Date.now())}
				/>
			</List.Item>
		)}
	/>
);

export default EventActivity;
