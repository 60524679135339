/**
 * Exteral dependencies
 */
import { useCallback } from 'react';
import Cookies from 'js-cookie';

/**
 * Internal dependencies
 */
import { LoginFields } from '@/auth';
import { setAuthRemember, useDispatch } from '@/store';
import { useHandleAuthAction } from '..';
import { usePocketBase } from '@/pocketbase';

const useAuthUtils = () => {
	const pb = usePocketBase();
	const dispatch = useDispatch();

	const handleAction = useHandleAuthAction();

	return useCallback(
		async ({ email, password, remember = false }: LoginFields) =>
			handleAction(async () => {
				const result = await pb
					.collection('users')
					.authWithPassword(email, password);

				Cookies.set('eventSwirlLoggedIn', 'true');

				dispatch(setAuthRemember(remember));

				return result;
			}),
		[dispatch, handleAction, pb]
	);
};

export default useAuthUtils;
