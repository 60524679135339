/**
 * Internal dependencies
 */
import { usePocketBase } from '@/pocketbase';
import { useSelector } from '@/store';
import { EventInvites } from '@/types';
import { useCallback } from 'react';

const useInvitation = () => {
	const { data, error, loading } = useSelector((state) => state.invitation);
	const pb = usePocketBase();

	const update = useCallback(
		(newData: Partial<EventInvites>) =>
			data?.id && pb.collection('event_invites').update(data.id, newData),
		[data?.id, pb]
	);

	const accept = useCallback(
		() =>
			data?.status === 'pending' &&
			update({
				status: 'accepted',
			}),
		[data?.status, update]
	);

	const reject = useCallback(
		() =>
			update({
				status: 'rejected',
			}),
		[update]
	);

	return [
		data,
		{
			accept,
			error,
			loading,
			reject,
			update,
		},
	] as const;
};

export default useInvitation;
