/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { SidebarLayout } from '@/layouts';
import { EventsList, useClosedEvents } from '@/event';

const HomePage: FC = () => (
	<SidebarLayout>
		<EventsList
			events={useClosedEvents()}
			noEventsLabel="Currently there are no closed events."
			title={['Closed ', 'events:']}
		/>
	</SidebarLayout>
);

export default HomePage;
