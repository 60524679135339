/**
 * External dependencies
 */
import { FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import classes from './Error.module.scss';
import { Paragraph } from '@/components';
import { ParagraphProps } from 'antd/es/typography/Paragraph';

const cx = classNames.bind(classes);

type ErrorProps = ParagraphProps;

const Error: FC<ErrorProps> = ({ children, className, ...props }) =>
	children ? (
		<Paragraph className={cx('error', className)} {...props}>
			{children}
		</Paragraph>
	) : null;

export default Error;
