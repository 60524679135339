/**
 * External dependencies
 */
import { FC } from 'react';
import { RouterProvider } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { AuthProvider } from '@/auth';
import { createRouter } from '@/router';
import { PocketBaseProvider } from '@/pocketbase';
import { StoreProvider } from '@/store';
import AntdConfig from '../AntdConfig';

const App: FC = () => (
	<StoreProvider>
		<PocketBaseProvider>
			<AntdConfig>
				<AuthProvider>
					<RouterProvider router={createRouter()} />
				</AuthProvider>
			</AntdConfig>
		</PocketBaseProvider>
	</StoreProvider>
);

export default App;
