/**
 * External dependencies
 */
import { FC, PropsWithChildren } from 'react';

/**
 * Internal dependencies
 */
import { Show } from '@/components';
import { useCanCreateEvent } from '@/event/hooks';
import { useGuardRedirect } from '@/hooks';

const CreateEventGuard: FC<PropsWithChildren> = ({ children }) => {
	const canCreate = useCanCreateEvent();

	useGuardRedirect({
		shouldRedirect: canCreate === false,
	});

	return <Show when={canCreate === true}>{children}</Show>;
};

export default CreateEventGuard;
