/**
 * External dependencies
 */
import { FC, PropsWithChildren, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { AvatarSizeString } from '@/types';
import { getUserProfileUrl, UserModel } from '@/user';
import Avatar from '../Avatar';
import classes from './UserHeader.module.scss';

const cx = classNames.bind(classes);

type UserHeaderProps = PropsWithChildren<{
	align?: 'left' | 'right';
	avatarBorder?: 'primary' | 'secondary';
	className?: string;
	heading?: ReactNode;
	size?: AvatarSizeString;
	subheading: ReactNode;
	user: Pick<UserModel, 'avatar' | 'name' | 'username'>;
}>;

const UserHeader: FC<UserHeaderProps> = ({
	align = 'left',
	avatarBorder,
	children,
	className,
	heading,
	size = 'default',
	subheading,
	user,
}) => (
	<div className={cx(className, 'wrap', `size-${size}`, `align-${align}`)}>
		<Link className={cx('avatar')} to={getUserProfileUrl(user.username)}>
			<Avatar
				border={
					avatarBorder
						? size === 'large'
							? 'large'
							: 'small'
						: false
				}
				color={avatarBorder}
				icon={<UserOutlined />}
				size={size}
				src={user.avatar}
			/>
		</Link>
		<div className={cx('heading')}>
			{heading ?? (
				<Link to={getUserProfileUrl(user.username)}>{user.name}</Link>
			)}
		</div>
		<div className={cx('subheading')}>{subheading}</div>
		{children && <div className={cx('children')}>{children}</div>}
	</div>
);

export default UserHeader;
