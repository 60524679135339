/**
 * External dependencies
 */
import { FC } from 'react';
import { Flex, PopoverProps } from 'antd';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { AccountActionsProps } from '../AccountActions/AccountActions';
import { ClosablePopover, Column, Text } from '@/components';
import AccountActions from '../AccountActions';
import classes from './AccountPopover.module.scss';
import EditableAvatar from '../EditableAvatar';
import Greeting from '../Greeting';
import type { AvatarUploadHandlerProps, UserData } from '@/user';

const cx = classNames.bind(classes);

type AccountPopoverProps = Omit<PopoverProps, 'content' | 'title'> &
	AccountActionsProps &
	AvatarUploadHandlerProps & {
		user: UserData;
	};

const AccountPopover: FC<AccountPopoverProps> = ({
	handleAvatarUpload,
	onLogOut,
	user: { avatar, email, username, name },
	...props
}) => (
	<ClosablePopover
		{...props}
		overlayClassName={cx('popover')}
		content={
			<>
				<Column gap={12} align="center">
					<Greeting size="medium">{name}</Greeting>
					<Text className={cx('email')}>{email}</Text>
				</Column>
				<Flex justify="center">
					<EditableAvatar
						handleUpload={handleAvatarUpload}
						src={avatar}
					/>
				</Flex>
				<AccountActions onLogOut={onLogOut} />
			</>
		}
		title={`@${username}`}
	/>
);

export default AccountPopover;
