/**
 * External dependencies
 */
import { FC, ImgHTMLAttributes } from 'react';

/**
 * Internal dependencies
 */
import placeholder from '@/images/placeholder.png';

type PlaceholderImageProps = ImgHTMLAttributes<HTMLImageElement> & {
	size?: number | `${number}`;
};

const PlaceholderImage: FC<PlaceholderImageProps> = ({
	alt = '',
	height,
	size,
	width,
	...props
}) => (
	<img
		{...props}
		alt={alt}
		height={height ?? size}
		src={placeholder}
		width={width ?? size}
	/>
);

export default PlaceholderImage;
