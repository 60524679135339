/**
 * External dependencies
 */
import { Form, FormItemProps, Input } from 'antd';
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { ReactComponent as EnvelopeIcon } from '@/images/icon-envelope.svg';

const EmailFormItem: FC<FormItemProps> = (props) => (
	<Form.Item
		label="Your Email"
		name="email"
		rules={[
			{
				required: true,
			},
			{
				type: 'email',
			},
		]}
		{...props}
	>
		<Input
			placeholder="your@email.com"
			prefix={<EnvelopeIcon />}
			size="large"
		/>
	</Form.Item>
);

export default EmailFormItem;
