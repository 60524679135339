/**
 * External dependencies
 */
import { FC } from 'react';
import { FlexProps } from 'antd';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import Flex from '../Flex';
import classes from './Column.module.scss';

const cx = classNames.bind(classes);

const Column: FC<FlexProps> = ({ className, ...props }) => (
	<Flex
		align="stretch"
		className={cx('column', className)}
		gap={30}
		{...props}
	/>
);

export default Column;
