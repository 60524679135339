/**
 * Exteral dependencies
 */
import { jwtDecode } from 'jwt-decode';
import { useCallback, useEffect } from 'react';

/**
 * Internal dependencies
 */
import { useAuthState } from '..';
import { usePocketBase } from '@/pocketbase';

const tokenRefreshBuffer = parseInt(
	process.env.REACT_APP_AUTH_TOKEN_REFRESH_BUFFER || '60'
);

const useTokenRefreshHandler = () => {
	const pb = usePocketBase();
	const { token } = useAuthState();

	const refreshSession = useCallback(async () => {
		try {
			await pb.collection('users').authRefresh();
		} catch (error) {
			pb.authStore.clear();
		}
	}, [pb]);

	useEffect(() => {
		refreshSession();
	}, [refreshSession]);

	useEffect(() => {
		if (!token) {
			return;
		}

		const tokenExp = jwtDecode(token).exp;

		if (!tokenExp) {
			return;
		}

		const timestamp = Math.floor(Date.now() / 1000);
		const delta = tokenExp - timestamp;

		if (delta < tokenRefreshBuffer) {
			refreshSession();
			return;
		}

		setTimeout(refreshSession, (delta - tokenRefreshBuffer) * 1000);
	}, [refreshSession, token]);

	return refreshSession;
};

export default useTokenRefreshHandler;
