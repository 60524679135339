/**
 * External dependencies
 */
import { FC, PropsWithChildren } from 'react';

/**
 * Internal dependencies
 */
import { AuthContext, useAuthUtils } from '@/auth';

type AuthProviderProps = PropsWithChildren;

const AuthProvider: FC<AuthProviderProps> = ({ children }) => (
	<AuthContext.Provider value={useAuthUtils()}>
		{children}
	</AuthContext.Provider>
);

export default AuthProvider;
