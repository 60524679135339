/**
 * External dependencies
 */
import { FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { primaryNavigation } from '@/config';
import classes from './PrimaryNav.module.scss';
import Navigation from '../Navigation';

const cx = classNames.bind(classes);

type PrimaryNavProps = {
	type: 'desktop' | 'mobile';
};

const PrimaryNav: FC<PrimaryNavProps> = ({ type }) => (
	<Navigation
		items={primaryNavigation}
		className={cx('navigation', type)}
		itemClassName={cx('item')}
		itemActiveClassName={cx('active')}
	/>
);

export default PrimaryNav;
