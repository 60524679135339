/**
 * External dependencies
 */
import { FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { DualFontText, Title } from '@/components';
import { EventBox, EventModel } from '@/event';
import classes from './EventsList.module.scss';

type EventsListProps = {
	events: EventModel[];
	noEventsLabel: string;
	title: [string, string];
};

const cx = classNames.bind(classes);

const EventsList: FC<EventsListProps> = ({ events, noEventsLabel, title }) => (
	<div className={cx('wrap')}>
		<Title className={cx('title')} level={2}>
			<DualFontText
				preClassName={cx('pre-title')}
				postClassName={cx('post-title')}
				parts={title}
			/>
		</Title>
		{events.length ? (
			events.map((event) => (
				<EventBox
					actionLabel="See answers"
					event={event}
					key={event.id}
				/>
			))
		) : (
			<div className={cx('empty')}>{noEventsLabel}</div>
		)}
	</div>
);

export default EventsList;
