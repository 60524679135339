/**
 * External dependencies
 */
import { FC } from 'react';
import BaseAvatarGroup from 'antd/es/avatar/group';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import classes from './AvatarGroup.module.scss';
import Avatar from '../Avatar';

type AvatarGroupProps = {
	avatars: (string | undefined)[];
	maxVisible?: number;
	moreColor?: 'primary' | 'secondary';
	showMoreCount?: boolean;
	onClick?: () => void;
	size?: 'default' | 'large';
};

const cx = classNames.bind(classes);

const AvatarGroup: FC<AvatarGroupProps> = ({
	avatars,
	maxVisible = 5,
	moreColor = 'primary',
	onClick,
	showMoreCount,
	size = 'default',
}) => (
	<BaseAvatarGroup
		className={cx('container')}
		size={size === 'default' ? 32 : 42}
	>
		{avatars.slice(0, maxVisible).map((avatar, index) => (
			<Avatar
				border={size === 'large' ? 'large' : true}
				className={cx('avatar')}
				key={index}
				src={avatar}
			/>
		))}
		{avatars.length > maxVisible && (
			<Avatar
				border={size === 'large' ? 'large' : true}
				className={cx('more', {
					'has-ellipsis': !showMoreCount,
					'has-more-count': showMoreCount,
					clickable: !!onClick,
				})}
				color={moreColor}
				onClick={onClick}
			>
				{showMoreCount ? (
					`+${avatars.length - maxVisible}`
				) : (
					<>&hellip;</>
				)}
			</Avatar>
		)}
	</BaseAvatarGroup>
);

export default AvatarGroup;
