/**
 * External dependencies
 */
import { useLocation } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { isRoute } from '@/router';

const useCurrentRoute = () => {
	const pathname = useLocation().pathname;

	return isRoute(pathname) ? pathname : null;
};

export default useCurrentRoute;
