/**
 * Internal dependencies
 */
import {
	onboardingRoutes,
	Route,
	useCurrentRoute,
	useIsPublicRoute,
} from '@/router';
import { useGuardRedirect } from '@/hooks';
import { useSelector } from '@/store';
import useCurrentUser from '../useCurrentUser';

const useUserRedirect = () => {
	const currentRoute = useCurrentRoute();
	const isPublicRoute = useIsPublicRoute().isCurrentPublicRoute;
	const prevRoute = useSelector(({ router }) => router.prevRoute);
	const user = useCurrentUser();

	const isOnboardingRoute =
		currentRoute && onboardingRoutes.includes(currentRoute);

	const redirectTo = user
		? !user.onboarded && !isOnboardingRoute
			? Route.SignUpSetup
			: isPublicRoute
				? prevRoute || Route.Home
				: null
		: !isPublicRoute
			? Route.SignIn
			: null;

	return useGuardRedirect({
		redirectTo,
	});
};

export default useUserRedirect;
