/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { AccountPopover } from '@/user';
import { UserBarProps } from '../UserBar';
import AccountButton from '../../AccountButton';

const UserBarDesktop: FC<UserBarProps> = ({ user, ...props }) => (
	<AccountPopover {...props} user={user}>
		<AccountButton avatar={user.avatar} name={user.name} />
	</AccountPopover>
);

export default UserBarDesktop;
