/**
 * External dependencies
 */
import { FC, Fragment } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { EventResponseModel } from '@/event/types';
import EventAnswer from '../EventAnswer';
import EventResponseHeader from '../EventResponseHeader';
import classes from './EventResponses.module.scss';

const cx = classNames.bind(classes);

type EventResponsesProps = {
	className?: string;
	onReact?: (response: EventResponseModel, reaction: string) => void;
	responses: EventResponseModel[];
};

const EventResponses: FC<EventResponsesProps> = ({
	className,
	onReact,
	responses,
}) => (
	<div className={cx('wrap', className)}>
		{responses.map((response, index) => (
			<Fragment key={response.id}>
				{(index === 0 ||
					response.author?.id !== responses[index - 1]?.author?.id) &&
					response.author && (
						<EventResponseHeader
							author={response.author}
							createdAt={response.createdAt}
						/>
					)}
				<EventAnswer
					content={response.content}
					media={response.files}
					onReact={(reaction) => onReact?.(response, reaction)}
					reactions={response.reactions}
				/>
			</Fragment>
		))}
	</div>
);

export default EventResponses;
