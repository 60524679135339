/**
 * External dependencies
 */
import { Reducer } from 'redux';

/**
 * Internal dependencies
 */
import { setInvitationLoading } from '@/store/actions/invitation';
import initialState from '../../initialState';
import isAction from '../../isAction';

const loading: Reducer<boolean> = (
	state = initialState.invitation.loading,
	action
) => (isAction(action, setInvitationLoading) ? action.payload : state);

export default loading;
