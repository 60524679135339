/**
 * Internal dependencies
 */
import { createAction } from '../action-creators';
import { RemoveSuffixFromKeys } from '@/types';
import { RootState } from '../types';
import { SET_CURRENT_ROUTE, SET_PREV_ROUTE, SET_ROUTES } from '../actionTypes';

type Routes = RemoveSuffixFromKeys<RootState['router'], 'Route'>;

export const setCurrentRoute =
	createAction<RootState['router']['currentRoute']>(SET_CURRENT_ROUTE);

export const setPrevRoute =
	createAction<RootState['router']['currentRoute']>(SET_PREV_ROUTE);

export const setRoutes = createAction<Partial<Routes>>(SET_ROUTES);
