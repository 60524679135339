/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { UserProfile, useUserProfileController } from '@/user';
import { BackButtonLayout } from '@/layouts';

const UserProfilePage: FC = () => {
	const { profile } = useUserProfileController();

	if (!profile) {
		return;
	}

	return (
		<BackButtonLayout
			backButtonLabel="Go back"
			title={`@${profile?.username}`}
		>
			<UserProfile profile={profile} />
		</BackButtonLayout>
	);
};

export default UserProfilePage;
