/**
 * External dependencies
 */
import { Reducer } from 'redux';

/**
 * Internal dependencies
 */
import { setCurrentEvent } from '../../actions';
import initialState from '../../initialState';
import isAction from '../../isAction';

const id: Reducer<string | null> = (
	state = initialState.currentEvent.id,
	action
) =>
	isAction(action, setCurrentEvent)
		? action.payload && typeof action.payload === 'object'
			? action.payload.id
			: action.payload
		: state;

export default id;
