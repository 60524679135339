/**
 * External dependencies
 */
import { replaceOrAppend } from 'radash';
import { useEffect } from 'react';

/**
 * Internal dependencies
 */
import { getEventResponseModelReactionsProps } from '@/event/utils';
import { upsetEventResponse, useDispatch } from '@/store';
import { usePocketBase } from '@/pocketbase';
import useCurrentEvent from '../useCurrentEvent';

const useReactionsSync = () => {
	const { responses } = useCurrentEvent();
	const dispatch = useDispatch();
	const pb = usePocketBase();

	useEffect(() => {
		const collection = pb.collection('event_reactions');

		collection.subscribe('*', ({ action, record }) => {
			const response = responses.find(
				(response) => response.id === record.response
			);

			if (!response) {
				return;
			}

			const reactionModels =
				action === 'delete'
					? response.reactionModels.filter(
							(reaction) => reaction.id !== record.id
						)
					: replaceOrAppend(
							response.reactionModels,
							record,
							(reaction) => reaction.id === record.id
						);

			dispatch(
				upsetEventResponse({
					...response,
					...getEventResponseModelReactionsProps(reactionModels),
				})
			);
		});

		return () => {
			collection.unsubscribe();
		};
	}, [dispatch, pb, responses]);
};

export default useReactionsSync;
