/**
 * External dependencies
 */
import { FC, PropsWithChildren } from 'react';

/**
 * Internal dependencies
 */
import { AppLayout } from '@/layouts';
import { PrimaryNav, SecondaryNav } from '@/components';
import classes from './SidebarLayout.module.scss';

type SidebarLayoutProps = {} & PropsWithChildren;

const SidebarLayout: FC<SidebarLayoutProps> = ({ children }) => (
	<AppLayout>
		<div className={classes.wrap}>
			<div className={classes.sidebar}>
				<div className={classes['sidebar-content']}>
					<PrimaryNav type="desktop" />
					<SecondaryNav />
				</div>
			</div>
			<div className={classes.content}>{children}</div>
		</div>
	</AppLayout>
);

export default SidebarLayout;
