/**
 * External dependencies
 */
import { FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { Text } from '@/components';
import classes from './DualFontText.module.scss';

const cx = classNames.bind(classes);

type DualFontTextProps = {
	parts: [string, string];
	postClassName?: string;
	preClassName?: string;
	size?: 'small' | 'medium' | 'large';
};

const DualFontText: FC<DualFontTextProps> = ({
	parts,
	postClassName,
	preClassName,
	size,
}) => (
	<Text className={cx(size)}>
		<Text italic className={cx('pre', 'font-eb-garamond', preClassName)}>
			{parts[0]}
		</Text>
		<Text strong className={cx('post', postClassName)}>
			{parts[1]}
		</Text>
	</Text>
);

export default DualFontText;
