/**
 * External dependencies
 */
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

/**
 * Internal dependencies
 */
import { useUserProfile } from '..';
import { Route } from '@/router';
import { getUserProfileModel } from '@/user';

const useUserProfileController = () => {
	const params = useParams();
	const navigate = useNavigate();
	const { profile, error } = useUserProfile(params.username ?? '');

	useEffect(() => {
		if (error) {
			navigate(Route.Home, { replace: true });
		}
	}, [error, navigate]);

	return {
		profile: profile ? getUserProfileModel(profile) : undefined,
	};
};

export default useUserProfileController;
