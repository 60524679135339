/**
 * External dependencies
 */
import { Route } from '@/router';
import { EventModel } from '../types';

const getEventUrl = (event: EventModel | string) =>
	Route.SingleEvent.replace(
		':eventId',
		typeof event === 'object' ? event.id : event
	);

export default getEventUrl;
