/**
 * External dependencies
 */
import { Reducer } from 'redux';

/**
 * Internal dependencies
 */
import { setAuthRemember } from '@/store/actions';
import initialState from '../../initialState';
import isAction from '../../isAction';

const remember: Reducer<boolean> = (
	state = initialState.auth.remember,
	action
) => (isAction(action, setAuthRemember) ? action.payload : state);

export default remember;
