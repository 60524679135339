/**
 * External dependencies
 */
import { NavDrawer } from '@/components';
import { FC, useState } from 'react';

/**
 * Internal dependencies
 */
import { UserBarProps } from '../UserBar';
import AccountButton from '../../AccountButton';

const UserBarMobile: FC<UserBarProps> = ({ onLogOut, user: { avatar } }) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<AccountButton
				active={open}
				avatar={avatar}
				onClick={() => setOpen((prevOpen) => !prevOpen)}
			/>
			<NavDrawer
				onClose={() => setOpen(false)}
				onLogOut={onLogOut}
				open={open}
			/>
		</>
	);
};

export default UserBarMobile;
