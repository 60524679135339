/**
 * External dependencies
 */
import { FC, useEffect, useState } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { allowedAvatarFormats } from '@/config';
import { AvatarUploadCallback } from '@/user/types';
import { Button } from '@/components';
import { ReactComponent as Edit } from '@/images/edit.svg';
import { Upload } from 'antd';
import Avatar from '../Avatar';
import classes from './EditableAvatar.module.scss';

const cx = classNames.bind(classes);

type EditableAvatarProps = {
	className?: string;
	handleUpload?: AvatarUploadCallback;
	src?: string;
};

const EditableAvatar: FC<EditableAvatarProps> = ({
	className,
	handleUpload,
	src,
}) => {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(false);
	}, [src]);

	return (
		<div className={cx('wrap', className)}>
			<Avatar src={src} size={140} placeholderScale={1} />
			<Upload
				accept={allowedAvatarFormats
					.map((format) => `image/${format}`)
					.join(',')}
				action={() => {
					setLoading(true);
					return '';
				}}
				customRequest={handleUpload}
				maxCount={1}
				showUploadList={false}
			>
				<Button
					className={cx('button')}
					disabled={loading}
					loading={loading}
					shape="circle"
					size="small"
				>
					{!loading && <Edit />}
				</Button>
			</Upload>
		</div>
	);
};

export default EditableAvatar;
