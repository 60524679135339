/**
 * Internal dependencies
 */
import { useEffect, useMemo } from 'react';

/**
 * External dependencies
 */
import { useDispatch, useSelector } from '@/store';
import { usePocketBase } from '@/pocketbase';
import {
	setInvitationData,
	setInvitationError,
	setInvitationLoading,
} from '@/store/actions/invitation';
import { useCurrentUser } from '@/user';
import { isFuture } from 'date-fns';
import { getInvitationModel } from '@/invitation/utils';

const useInvitationUtils = () => {
	const { data, error, loading } = useSelector((state) => state.invitation);
	const dispatch = useDispatch();
	const pb = usePocketBase();
	const user = useCurrentUser();

	const collection = useMemo(() => pb.collection('event_invites'), [pb]);

	useEffect(() => {
		if (!data?.id) {
			return;
		}

		const invitationId = data.id;

		collection.subscribe(
			invitationId,
			({ action, record }) => {
				dispatch(
					setInvitationData(
						action === 'update' &&
							user?.id === record.user &&
							isFuture(record.validUntil)
							? getInvitationModel(record)
							: null
					)
				);
			},
			{
				expand: [{ key: 'events_via_invite' }],
			}
		);

		return () => {
			collection.unsubscribe(invitationId);
		};
	}, [collection, data?.id, dispatch, user?.id]);

	useEffect(() => {
		if (!user || !error) {
			return;
		}

		const unsubscribe = () => {
			collection.unsubscribe('*');
		};

		collection.subscribe(
			'*',
			({ action, record }) => {
				if (
					['create', 'update'].includes(action) &&
					user.id === record.user &&
					isFuture(record.validUntil)
				) {
					dispatch(setInvitationData(getInvitationModel(record)));

					unsubscribe();
				}
			},
			{
				expand: [{ key: 'events_via_invite' }],
			}
		);

		return unsubscribe;
	}, [collection, dispatch, error, user]);

	useEffect(() => {
		if (data || error || loading) {
			return;
		}

		(async () => {
			if (!user) {
				return;
			}

			try {
				dispatch(setInvitationError(false));
				dispatch(setInvitationLoading(true));

				const invitation = await collection.getFirstListItem(
					`validUntil > "${new Date().toISOString()}" && status != "rejected" && user = "${user.id}"`,
					{
						expand: [{ key: 'events_via_invite' }],
					}
				);

				dispatch(setInvitationData(getInvitationModel(invitation)));
			} catch (error) {
				dispatch(setInvitationError(true));
			}

			dispatch(setInvitationLoading(false));
		})();
	}, [collection, data, dispatch, error, loading, user]);
};

export default useInvitationUtils;
