/**
 * External dependencies
 */
import { Divider, DrawerProps } from 'antd';
import { FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { AccountActions } from '@/user';
import { AccountActionsProps } from '@/user/components/AccountActions/AccountActions';
import classes from './NavDrawer.module.scss';
import Column from '../Column';
import MobileDrawer from '../MobileDrawer';
import PrimaryNav from '../PrimaryNav';
import SecondaryNav from '../SecondaryNav';

const cx = classNames.bind(classes);

type NavDravwerProps = Omit<DrawerProps, 'children'> & AccountActionsProps;

const NavDrawer: FC<NavDravwerProps> = ({
	rootClassName,
	onLogOut,
	...props
}) => (
	<MobileDrawer rootClassName={cx('drawer', rootClassName)} {...props}>
		<Column className={cx('column')}>
			<Column>
				<Divider />
				<PrimaryNav type="mobile" />
				<Divider />
				<SecondaryNav />
				<Divider />
			</Column>
			<AccountActions onLogOut={onLogOut} />
		</Column>
	</MobileDrawer>
);

export default NavDrawer;
