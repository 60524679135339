/**
 * External dependencies
 */
import { useCallback, useState } from 'react';

/**
 * Internal dependencies
 */
import { ErrorData } from '@/error/types';
import { getFieldErrors } from '@/error/utils';

const useError = () => {
	const [error, setError] = useState<ErrorData | null>(null);

	return [
		error,
		useCallback(
			(nextError: any) =>
				setError(
					!!nextError
						? {
								messages: [
									nextError instanceof Error
										? nextError.message
										: String(nextError),
								],
								fields: getFieldErrors(nextError),
							}
						: null
				),
			[]
		),
	] as const;
};

export default useError;
