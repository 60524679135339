/**
 * External dependencies
 */
import { FC } from 'react';
import { Divider } from 'antd';

/**
 * Internal dependencies
 */
import {
	AccountSettingsFormFields,
	AvatarUploadHandlerProps,
	BaseUserFields,
	PersonalInfoFields,
	UserData,
} from '@/user';
import { TitledForm } from '@/forms';
import { TitledFormProps } from '@/forms/components/TitledForm/TitledForm';
import classes from './AccountSetupForm.module.scss';

export type AccountSetupFormProps = Omit<
	TitledFormProps<AccountSettingsFormFields>,
	'title' | 'subtitle'
> &
	AvatarUploadHandlerProps & {
		user: UserData;
	};

const AccountSetupForm: FC<AccountSetupFormProps> = ({
	handleAvatarUpload,
	user,
	...props
}) => (
	<TitledForm
		buttonLabel="Confirm your settings"
		hasButtonArrow
		initialValues={user}
		subtitle="Complete your public profile"
		title={['Set up your ', 'account!']}
		{...props}
	>
		<BaseUserFields
			className={classes.fields}
			handleAvatarUpload={handleAvatarUpload}
			user={user}
		/>
		<Divider className={classes.divider} />
		<PersonalInfoFields />
	</TitledForm>
);

export default AccountSetupForm;
