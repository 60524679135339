/**
 * External dependencies
 */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { Route, useIsPublicRoute } from '@/router';
import { setRoutes } from '@/store';

type UseGuardRedirectArgs = {
	redirectTo?: string | null;
	shouldRedirect?: boolean;
};

const useGuardRedirect = ({
	redirectTo = Route.Home,
	shouldRedirect = true,
}: UseGuardRedirectArgs) => {
	const { isPublicRoute } = useIsPublicRoute();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const path = useLocation().pathname;

	useEffect(() => {
		if (shouldRedirect && redirectTo) {
			if (path !== redirectTo && !isPublicRoute(path)) {
				dispatch(
					setRoutes({
						prev: path as Route,
					})
				);
			}

			navigate(redirectTo, { replace: true });
		}
	}, [dispatch, isPublicRoute, navigate, path, redirectTo, shouldRedirect]);

	return shouldRedirect && !!redirectTo;
};

export default useGuardRedirect;
