/**
 * External dependencies
 */
import { FC, PropsWithChildren } from 'react';

export type ShowProps = PropsWithChildren<{
	when: boolean;
}>;

const Show: FC<ShowProps> = ({ children, when }) =>
	when ? <>{children}</> : null;

export default Show;
