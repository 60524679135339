/**
 * External dependencies
 */
import { addHours } from 'date-fns';
import { useNavigate } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { CreateEventFormProps } from '@/event/components/CreateEventForm/CreateEventForm';
import { Route } from '@/router';
import { usePocketBaseCall } from '@/hooks';
import { useInvitation } from '@/invitation';
import { setInvitationData, useDispatch } from '@/store';

type UseSignUpFormControllerReturn = Pick<
	CreateEventFormProps,
	'error' | 'onFinish' | 'processing' | 'invitationId' | 'onResign'
>;

const useCreateEventController = (): UseSignUpFormControllerReturn => {
	const [invitation, { reject }] = useInvitation();
	const { error, pocketBaseCall, processing } = usePocketBaseCall();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return {
		error: error?.messages[0] ?? null,
		invitationId: invitation?.id,
		onFinish: async ({ duration, instruction, subject, invite }) =>
			pocketBaseCall(async (pb) => {
				await pb.collection('events').create({
					author: pb.authStore.record?.id,
					endsAt: addHours(new Date(), duration).toISOString(),
					instruction,
					invite,
					subject,
				});

				// Reset invitation to force reload with related event.
				dispatch(setInvitationData(null));
				navigate(Route.Home);
			}),
		onResign: () => {
			navigate(Route.Home);
			reject();
		},
		processing,
	};
};

export default useCreateEventController;
