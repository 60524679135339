/**
 * External dependencies
 */
import { Button as BaseButton, ButtonProps as BaseButtonProps } from 'antd';
import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { isAbsoluteUrl } from '@/utils';

export type ButtonProps = {
	color?: BaseButtonProps['color'] | 'secondary' | 'neutral';
} & Omit<BaseButtonProps, 'color'>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			color = 'primary',
			href,
			iconPosition = 'end',
			onClick,
			shape = 'round',
			variant = 'solid',
			...props
		},
		ref
	) => {
		const navigate = useNavigate();

		return (
			<BaseButton
				// @ts-expect-error Any string works as color and adds proper classes.
				color={color}
				shape={variant !== 'link' ? shape : undefined}
				onClick={(e) => {
					if (href && !isAbsoluteUrl(href)) {
						e.preventDefault();
						navigate(href);
					}

					onClick?.(e);
				}}
				{...{ ref, variant, iconPosition }}
				{...props}
			/>
		);
	}
);

Button.displayName = 'Button';

export default Button;
