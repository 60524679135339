/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { AccountSetupForm, useAccountSetupFormController } from '@/sign-up';
import { useHandleAvatarUpload, useCurrentUser } from '@/user';

type SignUpSetupPageProps = {};

const SignUpSetupPage: FC<SignUpSetupPageProps> = () => (
	<AccountSetupForm
		{...useAccountSetupFormController()}
		handleAvatarUpload={useHandleAvatarUpload()}
		user={useCurrentUser()!}
	/>
);

export default SignUpSetupPage;
