/**
 * Internal dependencies
 */
import { Invitation, InvitationModel } from './types';

export const getInvitationModel = (
	invitation: Invitation
): InvitationModel => ({
	...invitation,
	hasEvent: !!invitation?.expand?.events_via_invite?.length,
});
