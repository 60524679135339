/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { secondaryNavigation } from '@/config';
import classes from './SecondaryNav.module.scss';
import Navigation from '../Navigation';

const SecondaryNav: FC = () => (
	<Navigation items={secondaryNavigation} itemClassName={classes.item} />
);

export default SecondaryNav;
