/**
 * External dependencies
 */
import { Layout } from 'antd';
import { FC } from 'react';

/**
 * Internal dependencies
 */
import classes from './Footer.module.scss';
import { Text } from '..';

const Footer: FC = () => (
	<Layout.Footer className={classes.footer}>
		<Text className={classes.text}>
			© {new Date().getFullYear()} EventSwirl. All rights reserved.
		</Text>
	</Layout.Footer>
);
export default Footer;
