/**
 * External dependencies
 */
import { useNavigate } from 'react-router-dom';

/**
 * Internal dependencies
 */
import {
	AccountSettingsFormFields,
	useAccountSettingsFormController,
} from '@/user';
import { Route } from '@/router';
import { TitledFormProps } from '@/forms/components/TitledForm/TitledForm';

type UseAccountSetupFormControllerReturn = Pick<
	TitledFormProps<AccountSettingsFormFields>,
	'onFinish' | 'processing' | 'error' | 'form'
>;

const useAccountSetupFormController =
	(): UseAccountSetupFormControllerReturn => {
		const navigate = useNavigate();

		return useAccountSettingsFormController({
			onSuccess: () => navigate(Route.SignUpShare),
			prepareData: (data, { email, username }) => ({
				...data,
				email,
				onboarded: true,
				username,
			}),
		});
	};

export default useAccountSetupFormController;
