/**
 * Exteral dependencies
 */
import { useEffect } from 'react';

/**
 * Internal dependencies
 */
import { getUserModel } from '@/user';
import { PocketBaseUser } from '@/user';
import { setAuthRemember, setAuthToken, setUser, useDispatch } from '@/store';
import { usePocketBase } from '@/pocketbase';

const useAuthStateWatcher = () => {
	const pb = usePocketBase();
	const dispatch = useDispatch();

	useEffect(
		() =>
			pb.authStore.onChange((nextToken, model) => {
				dispatch(setAuthToken(nextToken.length ? nextToken : null));

				const user = model ? (model as PocketBaseUser) : null;

				dispatch(setUser(user ? getUserModel(user) : null));

				if (!model) {
					// Logged out
					dispatch(setAuthRemember(false));
				}
			}),
		[dispatch, pb]
	);
};

export default useAuthStateWatcher;
