/**
 * External dependencies
 */
import { EventResponseModel, ReactionModel } from '../types';
import { pb } from '@/pocketbase';
import getReactions from './getReactions';

const getEventResponseModelReactionsProps = (
	reactions: ReactionModel[] | undefined
): Pick<
	EventResponseModel,
	'currentUserReaction' | 'reactionModels' | 'reactions'
> => ({
	currentUserReaction:
		reactions?.find(({ author }) => author === pb.authStore.record?.id) ??
		null,
	reactionModels: reactions ?? [],
	reactions: getReactions(reactions),
});

export default getEventResponseModelReactionsProps;
