/**
 * External dependencies
 */
import { FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { EventModel } from '@/event/types';
import { useEventActivityController } from '@/event/hooks';
import { WhiteBox, Widget } from '@/components';
import classes from './EventPageSidebar.module.scss';
import EventActivity from '../EventActivity';
import EventStatus from '../EventStatus';
import Participants from '../Participants';

const cx = classNames.bind(classes);

type EventPageSidebarProps = {
	className?: string;
	event: EventModel;
};

const EventPageSidebar: FC<EventPageSidebarProps> = ({ className, event }) => (
	<div className={cx('sidebar', className)}>
		<WhiteBox padding="small">
			<Participants layout="vertical" participants={event.attendees} />
		</WhiteBox>
		<WhiteBox padding="small">
			<EventStatus event={event} progressFirst titleDivider />
		</WhiteBox>
		<Widget title="Event’s Activity">
			<EventActivity {...useEventActivityController()} />
		</Widget>
	</div>
);

export default EventPageSidebar;
