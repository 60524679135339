/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { SignUpShareForm } from '@/sign-up';
import { useShareFormController } from '@/user';

const SignUpSharePage: FC = () => (
	<SignUpShareForm {...useShareFormController()} />
);

export default SignUpSharePage;
