/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { Column, Title } from '@/components';
import { EventModel, EventBox } from '@/event';
import { EventInvites } from '@/types';
import { Greeting, UserModel } from '@/user';
import { NoEventBox, CreateEventBox } from '@/homepage';
import classes from './HomepageContent.module.scss';

export type HomepageContentProps = {
	event: EventModel | null;
	hasLastEvent: boolean;
	invite: EventInvites | null;
	onCreateEvent: () => void;
	onJoinEvent: () => void;
	onRejectInvite: () => void;
	onViewLastEvent: () => void;
	title: string;
	user: UserModel;
};

const HomepageContent: FC<HomepageContentProps> = ({
	event,
	hasLastEvent,
	invite,
	onCreateEvent,
	onJoinEvent,
	onRejectInvite,
	onViewLastEvent,
	title,
	user,
}) => (
	<>
		<Column className={classes.header} gap={6}>
			<Greeting size="small">{user.firstName || user.username}</Greeting>
			<Title level={1} className={classes.title} color="primary">
				{title}
			</Title>
		</Column>
		{event ? (
			<EventBox
				actionLabel={event.hasJoined ? 'See your answer' : 'Join Event'}
				event={event}
				onActionClick={onJoinEvent}
			/>
		) : invite ? (
			<CreateEventBox
				invite={invite}
				onContinue={onCreateEvent}
				onCancel={onRejectInvite}
			/>
		) : (
			<NoEventBox
				hasLastEvent={hasLastEvent}
				onActionClick={onViewLastEvent}
			/>
		)}
	</>
);

export default HomepageContent;
