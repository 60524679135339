/**
 * External dependencies
 */
import { useState } from 'react';
import { ReactTagsWrapperProps } from 'react-tag-input';

export type Tag = NonNullable<ReactTagsWrapperProps['tags']>[number];

export type UseTagsArgs = {
	onChange?: (value: string) => void;
	separator?: string;
	validateTag?: (tag: Tag) => boolean;
	value?: string;
};

type UseTagsReturn = Pick<
	ReactTagsWrapperProps,
	| 'handleAddition'
	| 'handleDelete'
	| 'handleInputChange'
	| 'inputValue'
	| 'tags'
>;

const useTags = ({
	onChange,
	separator = ',',
	validateTag,
	value,
}: UseTagsArgs = {}): UseTagsReturn => {
	const [inputValue, setInputValue] = useState<string>('');

	const tags =
		value
			?.split(separator)
			.filter(Boolean)
			.map(
				(text): Tag => ({
					className: '',
					text,
					id: text,
				})
			) ?? [];

	return {
		handleAddition: (tag: Tag) => {
			if (validateTag && !validateTag(tag)) {
				return;
			}

			setInputValue('');
			onChange?.([value, tag.text].filter(Boolean).join(separator));
		},
		handleDelete: (index: number) =>
			onChange?.(
				tags
					.filter((_, i) => i !== index)
					.map(({ text }) => text)
					.join(separator)
			),
		handleInputChange: setInputValue,
		inputValue,
		tags,
	};
};

export default useTags;
