/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { DualFontText } from '@/components';

type GreetingProps = {
	children: string;
	size?: 'small' | 'medium' | 'large';
};

const Greeting: FC<GreetingProps> = ({ children, size }) => (
	<DualFontText parts={['Hey, ', `${children}!`]} size={size} />
);

export default Greeting;
