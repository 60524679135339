/**
 * External dependencies
 */
import { useState } from 'react';

/**
 * Internal dependencies
 */
import { Route } from '@/router';
import { ShareFormProps } from '@/user/components/ShareForm/ShareForm';
import { useCurrentUser } from '@/user';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePocketBase } from '@/pocketbase';

type UseShareFormControllerReturn = Pick<
	ShareFormProps,
	'onFinish' | 'processing' | 'inviteLink'
>;

const useShareFormController = (): UseShareFormControllerReturn => {
	const [processing, setProcessing] = useState(false);
	const navigate = useNavigate();
	const pb = usePocketBase();
	const user = useCurrentUser();
	const pathname = useLocation().pathname;
	const appUrl = window.location.toString().replace(pathname ?? '', '');

	return {
		inviteLink: `${appUrl}${Route.SignUp}/${user?.id}`,
		onFinish: async (data) => {
			setProcessing(true);

			try {
				await pb.send('/share', {
					body: data,
					method: 'POST',
				});
			} catch (error) {
				console.log(error);
			}

			setProcessing(false);
			navigate(Route.Home);
		},
		processing,
	};
};

export default useShareFormController;
