import { useSelector } from '@/store';

const useCurrentEvent = () =>
	useSelector(({ currentEvent, events, auth }) => {
		const event = events.data?.find(
			(event) => event.id === currentEvent.id
		);

		return {
			activities: currentEvent.activities,
			error: currentEvent.error,
			event,
			loading: events.loading,
			responses:
				(event?.isClosed
					? currentEvent.responses
					: currentEvent.responses?.filter(
							(response) => response.author?.id === auth.user?.id
						)) ?? [],
		};
	});

export default useCurrentEvent;
