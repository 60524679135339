/**
 * External dependencies
 */
import { ButtonHTMLAttributes, FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import classes from './GoogleLoginButton.module.scss';
import { ReactComponent as GoogleIcon } from '@/images/google.svg';

const cx = classNames.bind(classes);

type GoogleLoginButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const GoogleLoginButton: FC<GoogleLoginButtonProps> = ({
	children = 'Sign in with Google',
	className,
	...props
}) => (
	<button className={cx('button', 'font-roboto', className)} {...props}>
		<GoogleIcon />
		{children}
	</button>
);

export default GoogleLoginButton;
