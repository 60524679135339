/**
 * Internal dependencies
 */
import { EventResponse, EventResponseModel } from '../types';
import { getFileUrl } from '@/pocketbase';
import { getUserModel } from '@/user';
import getEventResponseModelReactionsProps from './getEventResponseModelReactionsProps';

const getEventResponseModel = ({
	author,
	expand,
	files,
	...response
}: EventResponse): EventResponseModel => ({
	...response,
	...getEventResponseModelReactionsProps(
		expand?.event_reactions_via_response
	),
	author: expand?.author ? getUserModel(expand?.author) : null,
	files: files.map((file) => getFileUrl(response, file)!),
});

export default getEventResponseModel;
