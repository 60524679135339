/**
 * External dependencies
 */
import { createTransform, PersistConfig } from 'redux-persist';
import { jwtDecode } from 'jwt-decode';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import Cookies from 'js-cookie';
import localforage from 'localforage';

/**
 * Internal dependencies
 */
import { RootState } from './types';
import initialState from './initialState';

type SubState = RootState['auth'];

const authTransform = createTransform<SubState, SubState, RootState>(
	undefined,
	(state) => {
		const shouldRestoreSession =
			Cookies.get('eventSwirlLoggedIn') === 'true' || state.remember;

		if (shouldRestoreSession && state.token) {
			const data = jwtDecode(state.token);

			return data.exp && data.exp > Date.now() / 1000
				? state
				: initialState.auth;
		}

		return state;
	},
	{
		whitelist: ['auth'],
	}
);

const createNoopStorage = () => ({
	getItem() {
		return Promise.resolve(null);
	},
	setItem(_key: string, value: unknown) {
		return Promise.resolve(value);
	},
	removeItem() {
		return Promise.resolve();
	},
});

export const persistOptions: PersistConfig<RootState> = {
	blacklist: ['currentEvent', 'events', 'invitation'],
	debug: true,
	key: 'root',
	keyPrefix: 'EventSwirl:',
	stateReconciler: autoMergeLevel2,
	storage:
		typeof window !== 'undefined'
			? localforage.createInstance({
					name: 'EventSwirl',
					storeName: 'EventSwirlData',
				})
			: createNoopStorage(),
	timeout: 0,
	transforms: [authTransform],
	version: 1,
};
