/**
 * External dependencies
 */

/**
 * Internal dependencies
 */
import {
	AccountSettingsPage,
	ClosedEventsPage,
	CreateEventPage,
	PasswordResetPage,
	HomePage,
	MyEventsPage,
	SignInPage,
	SignUpPage,
	SignUpSetupPage,
	SignUpSharePage,
	SingleEventPage,
	SupportPage,
	UserProfilePage,
	PasswordResetSuccessPage,
} from '@/pages';
import { Route, Routes } from './types';
import { SignUpLayout } from '@/sign-up';
import Main from '@/components/Main';

export const routes: Routes = {
	'/': {
		layout: Main,
		childRoutes: {
			'/': HomePage,
			'/account': AccountSettingsPage,
			'/events': () => null,
			'/events/:eventId': SingleEventPage,
			'/events/closed': ClosedEventsPage,
			'/events/create': CreateEventPage,
			'/events/joined': MyEventsPage,
			'/password-reset': PasswordResetPage,
			'/password-reset/:token': PasswordResetPage,
			'/password-reset/success': PasswordResetSuccessPage,
			'/sign-in': SignInPage,
			'/sign-up': {
				layout: SignUpLayout,
				childRoutes: {
					'/sign-up': SignUpPage,
					'/sign-up/:referrer': SignUpPage,
					'/sign-up/account-setup': SignUpSetupPage,
					'/sign-up/share': SignUpSharePage,
				},
			},
			'/support': SupportPage,
			'/users/:username': UserProfilePage,
		},
	},
};

export const publicRoutes = [
	`${Route.PasswordReset}/:token`,
	`${Route.SignUp}/:referrer`,
	Route.PasswordReset,
	Route.SignIn,
	Route.SignUp,
];

export const signUpRoutes = [
	Route.SignUp,
	Route.SignUpSetup,
	Route.SignUpShare,
];

export const onboardingRoutes = signUpRoutes.filter(
	(route) => !publicRoutes.includes(route)
);
