/**
 * External dependencies
 */
import { FC } from 'react';
import { Link } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { EmailPasswordForm, RequiredCheckboxFormField } from '@/forms';
import { useHandleReferrer, useSignUpFormController } from '@/sign-up';
import { Route } from '@/router';

const SignUpPage: FC = () => {
	useHandleReferrer();

	return (
		<EmailPasswordForm
			{...useSignUpFormController()}
			afterFields={
				<RequiredCheckboxFormField
					name="agreeTermsAndPrivacyPolicy"
					errorMessage="Please agree to the Terms and Privacy Policy."
				>
					I agree to all the Terms and Privacy Policy.
				</RequiredCheckboxFormField>
			}
			buttonLabel="Create account"
			footer={
				<>
					Alreade have an account?{' '}
					<Link to={Route.SignIn}>
						<strong>Sign In</strong>
					</Link>
				</>
			}
			subtitle="Join Event Swirl App and Sign up"
			title={['Let’s get ', 'started!']}
			withPasswordConfirmation
		/>
	);
};

export default SignUpPage;
