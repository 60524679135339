/**
 * External dependencies
 */
import { combineReducers } from 'redux';

/**
 * Internal dependencies
 */
import items from './items';

const reducer = combineReducers({
	items,
});

export default reducer;
