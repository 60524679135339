/**
 * External dependencies
 */
import { useNavigate } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { getHomepageTitle } from '@/homepage';
import { HomepageContentProps } from '@/homepage/components/HomepageContent/HomepageContent';
import { Route } from '@/router';
import { useAuthState } from '@/auth';
import { useInvitation } from '@/invitation';
import { getEventUrl, useEvents, useOngoingEvent } from '@/event';

const useHomepageController = (): HomepageContentProps => {
	const [invitation, { accept, reject }] = useInvitation();
	const { event } = useOngoingEvent();
	const { user } = useAuthState();
	const latestEvent = useEvents().events?.[0];
	const navigate = useNavigate();

	if (!user) {
		throw new Error('User is not logged in');
	}

	const onCreateEvent = async () => {
		navigate(Route.EventsCreate);
		accept();
	};

	const onViewLastEvent = () => {
		latestEvent && navigate(getEventUrl(latestEvent.id));
	};

	const invite =
		invitation && invitation.status !== 'rejected' && !invitation.hasEvent
			? invitation
			: null;

	return {
		event,
		hasLastEvent: !!latestEvent,
		invite,
		onCreateEvent,
		onJoinEvent: () => event && navigate(getEventUrl(event)),
		onRejectInvite: reject,
		onViewLastEvent,
		title: getHomepageTitle(!!event, invite?.status),
		user,
	};
};

export default useHomepageController;
