/**
 * External dependencies
 */
import { FC, ReactNode } from 'react';
import classNames from 'classnames/bind';
import { Select as BaseSelect, SelectProps as BaseSelectProps } from 'antd';

/**
 * Internal dependencies
 */
import classes from './Select.module.scss';
import FormFieldStatusIcon from '../FormFieldStatusIcon';
import { FormFieldStatus } from '@/types';

export type SelectProps = {
	prefix?: ReactNode;
	suffix?: ReactNode;
	status?: FormFieldStatus;
} & Omit<BaseSelectProps, 'prefix' | 'status'>;

const cx = classNames.bind(classes);

const Select: FC<SelectProps> = ({
	className,
	prefix,
	suffix,
	status,
	...props
}) => (
	<div
		className={cx('wrapper', {
			'with-prefix': !!prefix,
			'with-suffix': !!suffix || status,
			success: status === 'success',
			error: status === 'error',
			disabled: props.disabled,
		})}
	>
		{!!prefix && <span className={classes['prefix']}>{prefix}</span>}
		<BaseSelect className={cx('select', className)} {...props} />
		{(!!suffix || status) && (
			<span className={classes['suffix']}>
				{suffix ?? (
					<FormFieldStatusIcon
						status={status}
						className={classes.statusIcon}
					/>
				)}
			</span>
		)}
	</div>
);

export default Select;
