/**
 * External dependencies
 */
import { useCallback } from 'react';

/**
 * Internal dependencies
 */
import { usePocketBaseCall } from '@/hooks';
import { UserUpdateData } from '@/user/types';
import useCurrentUser from '../useCurrentUser';

const useUpdateUser = () => {
	const user = useCurrentUser();
	const { error, processing, pocketBaseCall } = usePocketBaseCall();

	return [
		useCallback(
			(data: UserUpdateData) =>
				user?.id &&
				pocketBaseCall(async (pb) => {
					await pb.collection('users').update(user.id, data);
				}),
			[pocketBaseCall, user?.id]
		),
		{ error, processing },
	] as const;
};

export default useUpdateUser;
