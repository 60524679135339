/**
 * External dependencies
 */
import { FC } from 'react';
import FormItem from 'antd/es/form/FormItem';

/**
 * Internal dependencies
 */
import { Form, FormProps, Input, SubmitButton, Textarea } from '@/forms';
import classes from './ContactForm.module.scss';

type ContactFormFields = {
	subject: string;
	message: string;
};

export type ContactFormProps = FormProps<ContactFormFields>;

const ContactForm: FC<ContactFormProps> = ({ ...props }) => {
	const { processing, success } = props;

	return (
		<Form {...props} disabled={!!success || processing} layout="vertical">
			<FormItem
				label="Subject"
				name="subject"
				rules={[{ required: true, message: 'Subject is required' }]}
			>
				<Input className={classes.input} placeholder="I need help!" />
			</FormItem>
			<FormItem
				label="Message"
				name="message"
				rules={[{ required: true, message: 'Message is required' }]}
			>
				<Textarea className={classes.input} />
			</FormItem>
			<SubmitButton
				className={classes.submit}
				size="large"
				hasArrow
				loading={props.processing}
			>
				Send
			</SubmitButton>
		</Form>
	);
};

export default ContactForm;
