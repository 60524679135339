/**
 * Internal dependencies
 */
import { ReactionModel } from '../types';

const getReactions = (models: ReactionModel[] | undefined) =>
	models
		? Object.entries(
				models.reduce(
					(acc, { reaction }) => ({
						...acc,
						[reaction]: reaction in acc ? acc[reaction] + 1 : 1,
					}),
					{} as Record<string, number>
				)
			).map(([type, count]) => ({
				type,
				count,
			}))
		: [];

export default getReactions;
