/**
 * External dependencies
 */
import { Form, FormItemProps } from 'antd';
import { FC } from 'react';

/**
 * Internal dependencies
 */
import classes from './CheckableFormItem.module.scss';
import Checkbox from '../Checkbox';

type CheckableFormItemProps = FormItemProps & {
	checkboxLabel: string;
	checkboxName: string;
};

const CheckableFormItem: FC<CheckableFormItemProps> = ({
	checkboxName,
	checkboxLabel,
	label,
	...props
}) => (
	<Form.Item
		rootClassName={classes.item}
		label={
			<>
				<span>{label}</span>
				<Form.Item name={checkboxName} noStyle valuePropName="checked">
					<Checkbox rootClassName={classes.checkbox}>
						{checkboxLabel}
					</Checkbox>
				</Form.Item>
			</>
		}
		{...props}
	/>
);
export default CheckableFormItem;
