/**
 * External dependencies
 */
import { ButtonHTMLAttributes, FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import classes from './FacebookLoginButton.module.scss';
import { ReactComponent as FacebookIcon } from '@/images/facebook.svg';

const cx = classNames.bind(classes);

type FacebookLoginButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const FacebookLoginButton: FC<FacebookLoginButtonProps> = ({
	children = 'Continue with Facebook',
	className,
	...props
}) => (
	<button className={cx('button', className)} {...props}>
		<FacebookIcon />
		{children}
	</button>
);

export default FacebookLoginButton;
