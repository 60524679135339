/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { useRouteWatcher } from '@/router/hooks';

const RouteWatcher: FC = () => {
	useRouteWatcher();

	return null;
};

export default RouteWatcher;
