/**
 * External dependencies
 */
import { FC, useMemo, useState } from 'react';

/**
 * Internal dependencies
 */
import {
	AccountSettingsFormFields,
	AvatarUploadHandlerProps,
	UserModel,
} from '@/user/types';
import { Flex } from '@/components';
import { Form, FormProps, SubmitButton } from '@/forms';
import BaseUserFields from '../BaseUserFields';
import classes from './AccountSettingsForm.module.scss';
import PersonalInfoFields from '../PersonalInfoFields';

type AccountSettingsFormProps = FormProps<AccountSettingsFormFields> &
	AvatarUploadHandlerProps & {
		user: UserModel;
	};

const AccountSettingsForm: FC<AccountSettingsFormProps> = ({
	handleAvatarUpload,
	onFinish,
	user,
	...props
}) => {
	const initialValues = useMemo<AccountSettingsFormFields>(
		() => ({
			bio: user.bio,
			email: user.email,
			emailShowOnProfile: user.profileFields.includes('email'),
			firstName: user.firstName,
			firstNameShowOnProfile: user.profileFields.includes('firstName'),
			lastName: user.lastName,
			lastNameShowOnProfile: user.profileFields.includes('lastName'),
			location: user.location,
			locationShowOnProfile: user.profileFields.includes('location'),
			username: user.username,
		}),
		[user]
	);

	const [changes, setChanges] = useState<Partial<AccountSettingsFormFields>>(
		{}
	);

	return (
		<Form
			initialValues={initialValues}
			layout="vertical"
			onFinish={(data) => {
				onFinish?.(data);
				setChanges({});
			}}
			onValuesChange={(newValues: Partial<AccountSettingsFormFields>) =>
				Object.entries(newValues).forEach(([key, value]) =>
					setChanges((prev) => {
						if (
							value !==
							initialValues[
								key as keyof AccountSettingsFormFields
							]
						) {
							return {
								...prev,
								[key]: value,
							};
						}

						const next = { ...prev };
						delete next[key as keyof AccountSettingsFormFields];
						return next;
					})
				)
			}
			{...props}
		>
			<Flex justify="space-between" className={classes.content}>
				<BaseUserFields
					className={classes.left}
					disabled
					handleAvatarUpload={handleAvatarUpload}
					user={user}
				/>
				<PersonalInfoFields className={classes.right} />
			</Flex>
			<Flex className={classes.footer}>
				<SubmitButton
					className={classes.button}
					disabled={!Object.keys(changes).length}
					hasArrow
					loading={props.processing}
				>
					Confirm your settings
				</SubmitButton>
			</Flex>
		</Form>
	);
};

export default AccountSettingsForm;
