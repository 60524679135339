/**
 * Internal dependencies
 */
import { EmailPasswordFormProps } from '@/forms/components/EmailPasswordForm/EmailPasswordForm';
import { SignInPageFields } from '@/pages/SignInPage/SignInPage';
import { useAuth } from '@/auth';

const useSignInFormController = () => {
	const { loginWithProvider, login, loading, error } = useAuth();

	return {
		error: error ? 'Invalid email or password' : undefined,
		onFinish: login,
		onSocialButtonClick: loginWithProvider,
		processing: loading,
	} satisfies Partial<
		EmailPasswordFormProps<SignInPageFields, false, true, true>
	>;
};

export default useSignInFormController;
