/**
 * Internal dependencies
 */
import { EventActivityProps } from '@/event/components/EventActivity/types';
import { getActivityData, isActivityData } from './utils';
import useCurrentEvent from '../useCurrentEvent';

const useEventActivityController = (): EventActivityProps => {
	const { activities, event } = useCurrentEvent();

	return {
		activities:
			(event &&
				activities
					?.map((activity) => getActivityData(event, activity))
					.filter(isActivityData)) ||
			[],
		loading: activities === null,
	};
};

export default useEventActivityController;
