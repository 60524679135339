/**
 * Internal dependencies
 */
import { homepageTitle } from '@/config';

const getHomepageTitle = (hasEvent: boolean, inviteStatus?: string) =>
	hasEvent
		? homepageTitle.default
		: inviteStatus === 'pending'
			? homepageTitle.invited
			: inviteStatus
				? homepageTitle.accepted
				: homepageTitle.noEvent;

export default getHomepageTitle;
