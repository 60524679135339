/**
 * External dependencies
 */
import ReactDOM from 'react-dom/client';

/**
 * Internal dependencies
 */
import './scss/main.scss';
import { App } from '@/components';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(<App />);

reportWebVitals();
