/**
 * Internal dependencies
 */
import { AnswerFormProps } from '@/event/components/AnswerForm/AnswerForm';
import { Form } from '@/forms';
import { usePocketBaseCall } from '@/hooks';

type UseAnswerFormControllerReturn = Pick<
	AnswerFormProps,
	'error' | 'form' | 'onFinish' | 'processing'
>;

const useAnswerFormController = (
	event: string
): UseAnswerFormControllerReturn => {
	const [form] = Form.useForm();
	const { error, pocketBaseCall, processing } = usePocketBaseCall();

	return {
		error: error?.messages[0] ?? null,
		form,
		onFinish: ({ files, content }) =>
			(!!files?.length || !!content) &&
			pocketBaseCall(async (pb) => {
				await pb.collection('event_responses').create({
					author: pb.authStore.record?.id,
					content: content || null,
					event,
					files: files?.map((file) => file.originFileObj),
				});

				form.resetFields();
			}),
		processing,
	};
};

export default useAnswerFormController;
