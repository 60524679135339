/**
 * External dependencies
 */
import { FC } from 'react';
import {
	WithContext as ReactTags,
	ReactTagsWrapperProps,
	SEPARATORS,
} from 'react-tag-input';

/**
 * Internal dependencies
 */
import { useTags } from '@/forms/hooks';
import { UseTagsArgs } from '@/forms/hooks/useTags/useTags';
import classes from './TagsInput.module.scss';
import TagsInputRemove from './Remove';

export type TagsInputProps = UseTagsArgs &
	ReactTagsWrapperProps & {
		onChange?: (value: string) => void;
		value?: string;
	};

const TagsInput: FC<TagsInputProps> = ({
	onChange,
	validateTag,
	value,
	...props
}) => (
	<ReactTags
		allowDragDrop={false}
		classNames={
			{
				tagInputField: classes.input,
				tag: classes.tag,
				selected: classes.tags,
			} as any
		}
		inputFieldPosition="top"
		inputProps={{
			enterKeyHint: 'enter',
		}}
		removeComponent={TagsInputRemove}
		separators={Object.values(SEPARATORS)}
		{...useTags({ validateTag, onChange, value })}
		{...props}
	/>
);

export default TagsInput;
