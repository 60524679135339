/**
 * External dependencies
 */
import { ComponentType } from 'react';

export enum Route {
	Account = '/account',
	Events = '/events',
	EventsClosed = '/events/closed',
	EventsCreate = '/events/create',
	Home = '/',
	JoinedEvents = '/events/joined',
	PasswordReset = '/password-reset',
	PasswordResetSuccess = '/password-reset/success',
	SignIn = '/sign-in',
	SignUp = '/sign-up',
	SignUpReferrer = '/sign-up/:referrer',
	SignUpSetup = '/sign-up/account-setup',
	SignUpShare = '/sign-up/share',
	SingleEvent = '/events/:eventId',
	Support = '/support',
	UsersProfile = '/users/:username',
}

export type RouteConfig = {
	childRoutes: Routes;
	layout?: ComponentType;
};

export type Routes = Partial<Record<Route, ComponentType | RouteConfig>> &
	Record<string, ComponentType | RouteConfig>;
