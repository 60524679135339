/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { Button } from '@/components';
import { ReactComponent as ChevronRight } from '@/images/chevron-right.svg';
import { Route } from '@/router';
import { ShareForm } from '@/user';
import { ShareFormProps } from '@/user/components/ShareForm/ShareForm';

export type SignUpShareFormProps = Omit<ShareFormProps, 'subtitle'>;

const SignUpShareForm: FC<SignUpShareFormProps> = (props) => (
	<ShareForm
		{...props}
		subtitle="Invite your friends"
		footer={
			<Button variant="link" icon={<ChevronRight />} href={Route.Home}>
				Skip this step
			</Button>
		}
		centered
		buttonVariant="solid"
	/>
);

export default SignUpShareForm;
