/**
 * External dependencies
 */
import { BasicProps, Header as LayoutHeader } from 'antd/es/layout/layout';
import { FC, useRef } from 'react';
import { Flex } from 'antd';
import classnames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { AccountActionsProps } from '@/user/components/AccountActions/AccountActions';
import { AvatarUploadHandlerProps, UserBar, UserData } from '@/user';
import { useHeaderHeightObserver } from '@/hooks';
import classes from './Header.module.scss';
import Logo from '../Logo';

const cx = classnames.bind(classes);

type HeaderProps = BasicProps &
	AvatarUploadHandlerProps &
	AccountActionsProps & {
		user?: UserData;
	};

const Header: FC<HeaderProps> = ({
	children,
	className,
	handleAvatarUpload,
	onLogOut,
	user,
	...props
}) => {
	const ref = useRef<HTMLDivElement>(null);

	useHeaderHeightObserver(ref);

	return (
		<LayoutHeader ref={ref} className={cx('header', className)} {...props}>
			<Flex justify="space-between" align="center" gap="middle">
				<Logo />
				{children}
				{user && (
					<UserBar
						handleAvatarUpload={handleAvatarUpload}
						onLogOut={onLogOut}
						user={user}
					/>
				)}
			</Flex>
		</LayoutHeader>
	);
};

export default Header;
