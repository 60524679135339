/**
 * External dependencies
 */
import { combineReducers } from 'redux';

/**
 * Internal dependencies
 */
import * as reducers from './reducers';
import { persistReducer } from 'redux-persist';
import { persistOptions } from './persist';

export const rootReducer = combineReducers(reducers);

export const reducer = persistReducer(persistOptions, rootReducer);
