/**
 * External dependencies
 */
import { FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import classes from './EventReactions.module.scss';
import { Reaction } from '@/event';

type EventReactionsProps = {
	reactions: Reaction[];
};

const cx = classNames.bind(classes);

const EventReactions: FC<EventReactionsProps> = ({ reactions }) => (
	<div className={cx('wrap')}>
		{reactions.map(({ type, count }) => (
			<div key={type} className={cx('reaction')}>
				<span className={cx('emoji')}>{type}</span>
				<span className={cx('count')}>{count}</span>
			</div>
		))}
	</div>
);

export default EventReactions;
