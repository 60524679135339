/**
 * External dependencies
 */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

/**
 * Internal dependencies
 */
import {
	EmailPasswordFormFields,
	EmailPasswordFormProps,
} from '@/forms/components/EmailPasswordForm/EmailPasswordForm';
import { usePocketBaseCall } from '@/hooks';
import { Form } from '@/forms';
import { getFieldErrorMessage } from '@/error/utils';

type UsePasswordResetFormControllerReturn = Pick<
	EmailPasswordFormProps,
	| 'buttonLabel'
	| 'error'
	| 'form'
	| 'onFinish'
	| 'processing'
	| 'subtitle'
	| 'title'
	| 'withEmail'
	| 'withPassword'
	| 'withPasswordConfirmation'
> & {
	status: 'email-sent' | 'password-set' | null;
};

const usePasswordResetFormController =
	(): UsePasswordResetFormControllerReturn => {
		const [form] = Form.useForm<EmailPasswordFormFields>();
		const [success, setSuccess] = useState(false);
		const { pocketBaseCall, error, processing } = usePocketBaseCall();
		const { token } = useParams();

		const tokenError = getFieldErrorMessage(error, 'token');
		const passwordError =
			!tokenError && getFieldErrorMessage(error, 'password');

		useEffect(() => {
			if (passwordError) {
				form.setFields([
					{
						name: 'password',
						errors: [passwordError],
					},
				]);
			}
		}, [form, passwordError]);

		return {
			buttonLabel: token ? 'Set new Password' : 'Reset Password',
			error:
				tokenError ||
				(!passwordError && error
					? 'Something went wrong. Please try again.'
					: undefined),
			form,
			onFinish: async ({ email, password, passwordConfirm }) => {
				if (email) {
					pocketBaseCall(async (pb) => {
						await pb
							.collection('users')
							.requestPasswordReset(email);

						setSuccess(true);
					});
				}

				if (password && passwordConfirm && token) {
					pocketBaseCall(async (pb) => {
						await pb
							.collection('users')
							.confirmPasswordReset(
								token,
								password,
								passwordConfirm
							);

						setSuccess(true);
					});
				}
			},
			processing,
			subtitle: !token
				? "No worries, we'll send you reset instructions."
				: undefined,
			status: success ? (token ? 'password-set' : 'email-sent') : null,
			title: token
				? ['Set new ', 'Password']
				: ['Reset your ', 'Password'],
			withEmail: !token && !success,
			withPassword: !!token,
			withPasswordConfirmation: true,
		};
	};

export default usePasswordResetFormController;
