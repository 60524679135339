/**
 * External dependencies
 */
import { combineReducers } from 'redux';

/**
 * Internal dependencies
 */
import headerHeight from './headerHeight';

const reducer = combineReducers({
	headerHeight,
});

export default reducer;
