/**
 * External dependencies
 */
import { FC, useState } from 'react';
import { Form } from 'antd';

/**
 * Internal dependencies
 */
import { Text } from '@/components';
import { Textarea } from '@/forms';
import classes from './EventInstructionsField.module.scss';

type EventInstructionsFieldProps = {};

const EventInstructionsField: FC<EventInstructionsFieldProps> = () => {
	const [length, setLength] = useState(0);

	return (
		<div className={classes.wrap}>
			<Form.Item
				rootClassName={classes.item}
				label="Instructions For an Event"
				name="instruction"
				rules={[{ required: true }]}
			>
				<Textarea
					maxLength={250}
					onChange={(e) => setLength(e.target.value.length)}
					placeholder="Enter your directive..."
				/>
			</Form.Item>
			<Text className={classes.status}>{length}/250</Text>
		</div>
	);
};

export default EventInstructionsField;
