/**
 * External dependencies
 */
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { RuleObject } from 'antd/es/form';
import { useState } from 'react';

const useRequiredCheckboxValidator = (error = 'Please check this field.') => {
	const [checked, setChecked] = useState(false);

	const validator: RuleObject['validator'] = (_, __, callback) =>
		callback(checked ? undefined : error);

	return {
		checked,
		onChange: (event: CheckboxChangeEvent) =>
			setChecked(event.target.checked),
		validator,
	};
};

export default useRequiredCheckboxValidator;
