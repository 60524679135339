/**
 * External dependencies
 */
import { Divider } from 'antd';
import { FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import {
	Button,
	DualFontText,
	Flex,
	Paragraph,
	Title,
	WhiteBox,
} from '@/components';
import { Form, Input, SubmitButton } from '@/forms';
import { FormProps } from '@/forms';
import classes from './CreateEventForm.module.scss';
import EventInstructionsField from '../EventInstructionsField';
import EventTips from '../EventTips';
import TimeRangeItem from '../TimeRangeItem';

const cx = classNames.bind(classes);

type CreateEventFormValues = {
	duration: number;
	instruction: string;
	invite?: string;
	subject: string;
};

export type CreateEventFormProps = {
	invitationId?: string;
	onResign?: () => void;
	onTipsOpen?: () => void;
} & Omit<FormProps<CreateEventFormValues>, 'children'>;

const CreateEventForm: FC<CreateEventFormProps> = ({
	disabled,
	invitationId,
	onResign,
	onTipsOpen,
	processing,
	...formProps
}) => (
	<WhiteBox>
		<Title level={2} className={cx('title')}>
			<DualFontText size="large" parts={['Set up your ', 'Event']} />
		</Title>
		<Flex className={cx('wrap')}>
			<Form
				layout="vertical"
				className={cx('form')}
				initialValues={{
					duration: 48,
					invite: invitationId,
				}}
				{...formProps}
				disabled={disabled || processing}
			>
				<Form.Item noStyle name="invite" hidden>
					<Input type="hidden" />
				</Form.Item>
				<div className={cx('heading')}>
					<Paragraph>Set your event instructions</Paragraph>
				</div>
				<Form.Item
					label="Subject"
					name="subject"
					rules={[{ required: true }]}
				>
					<Input placeholder="Enter your event subject" />
				</Form.Item>
				<EventInstructionsField />
				<Divider />
				<Paragraph>Set a time range</Paragraph>
				<TimeRangeItem />
				<Divider />
				<Flex className={cx('actions')}>
					<SubmitButton hasArrow loading={processing}>
						Accept & Share
					</SubmitButton>
					<Button
						color="secondary"
						onClick={(e) => {
							e.preventDefault();
							onResign?.();
						}}
						size="large"
						variant="link"
					>
						Resign & Pass it on
					</Button>
				</Flex>
			</Form>
			<div className={cx('sidebar')}>
				<EventTips onMoreClick={onTipsOpen} />
			</div>
		</Flex>
	</WhiteBox>
);

export default CreateEventForm;
