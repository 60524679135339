import { useCallback } from 'react';

const useCatch = () =>
	useCallback(
		async <T extends any = void>(callback: () => T | Promise<T>) => {
			try {
				return await callback();
			} catch (error) {
				console.log(error);

				window.alert('Something went wrong. Please try again.');
			}
		},
		[]
	);

export default useCatch;
