/**
 * External dependencies
 */
import { Form, Radio } from 'antd';
import { FC } from 'react';

/**
 * Internal dependencies
 */
import classes from './TimeRangeItem.module.scss';

const options = [24, 48, 72];

const TimeRangeItem: FC = () => (
	<Form.Item className={classes.field} name="duration">
		<Radio.Group
			optionType="button"
			options={options.map((value) => ({
				value,
				label: `${value}h`,
			}))}
		/>
	</Form.Item>
);

export default TimeRangeItem;
