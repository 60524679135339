/**
 * External dependencies
 */
import { FC } from 'react';

type PasswordResetSuccessPageProps = {};

const PasswordResetSuccessPage: FC<PasswordResetSuccessPageProps> = () => null;

export default PasswordResetSuccessPage;
