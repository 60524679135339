/**
 * External dependencies
 */
import { Reducer } from 'redux';

/**
 * Internal dependencies
 */
import { setFaqItems } from '@/store/actions';
import initialState from '../../initialState';
import isAction from '../../isAction';
import { Faq } from '@/types';

const error: Reducer<Faq[] | null> = (
	state = initialState.faq.items,
	action
) => (isAction(action, setFaqItems) ? action.payload : state);

export default error;
