/**
 * External dependencies
 */
import { Reducer } from 'redux';

/**
 * Internal dependencies
 */
import { setAuthLoading } from '@/store/actions';
import initialState from '../../initialState';
import isAction from '../../isAction';

const loading: Reducer<boolean> = (
	state = initialState.auth.loading,
	action
) => (isAction(action, setAuthLoading) ? action.payload : state);

export default loading;
