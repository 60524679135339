/**
 * External dependencies
 */
import { FC } from 'react';
import { Divider } from 'antd';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { Reaction } from '@/event/types';
import { getTotalReactionsCount } from '@/event/utils';
import { reactionEmojis } from '@/config';
import classes from './EventAnswer.module.scss';
import EventMedia from '../EventMedia';
import EventReactButton from '../EventReactButton';
import EventReactions from '../EventReactions';

type EventAnswerProps = {
	content: string;
	media: string[];
	onReact: (reaction: string) => void;
	reactions: Reaction[];
};

const cx = classNames.bind(classes);

const EventAnswer: FC<EventAnswerProps> = ({
	content = '',
	media = [],
	reactions = [],
	onReact,
}) => (
	<div className={cx('wrap', 'font-inter')}>
		{media.length > 0 && (
			<EventMedia className={cx('media')} media={media} />
		)}
		<div
			className={cx('content')}
			dangerouslySetInnerHTML={{ __html: content }}
		/>
		{getTotalReactionsCount(reactions) > 0 && (
			<EventReactions reactions={reactions} />
		)}
		<Divider className={cx('divider')} />
		<EventReactButton
			className={cx('react-button')}
			emojis={reactionEmojis}
			onReact={onReact}
		/>
	</div>
);

export default EventAnswer;
