/**
 * External dependencies
 */
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

/**
 * Internal dependencies
 */
import { usePocketBaseCall } from '@/hooks';
import { setFaqItems, useSelector } from '@/store';

const useFaqItems = () => {
	const dispatch = useDispatch();
	const items = useSelector(({ faq }) => faq.items);
	const { pocketBaseCall } = usePocketBaseCall();

	useEffect(() => {
		pocketBaseCall(async (pb) => {
			dispatch(
				setFaqItems(
					await pb.collection('faq').getFullList({ sort: 'order' })
				)
			);
		});
	}, [dispatch, pocketBaseCall]);

	return items ?? [];
};

export default useFaqItems;
