// Auth
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const SET_AUTH_REFERRER = 'SET_AUTH_REFERRER';
export const SET_AUTH_REMEMBER = 'SET_AUTH_REMEMBER';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_USER = 'SET_USER';

// Event
export const REMOVE_EVENT = 'REMOVE_EVENT';
export const REMOVE_EVENT_RESPONSE = 'UPSET_EVENT_RESPONSE';
export const SET_CURRENT_EVENT = 'SET_CURRENT_EVENT';
export const SET_EVENT_ACTIVITIES = 'SET_EVENT_ACTIVITIES';
export const SET_EVENT_RESPONSES = 'SET_EVENT_RESPONSES';
export const SET_EVENT_RESPONSES_ERROR = 'SET_EVENT_RESPONSES_ERROR';
export const SET_EVENT_RESPONSES_LOADING = 'SET_EVENT_RESPONSES_LOADING';
export const SET_EVENTS = 'SET_EVENTS';
export const SET_EVENTS_ERROR = 'SET_EVENTS_ERROR';
export const SET_EVENTS_LOADING = 'SET_EVENTS_LOADING';
export const UPSET_EVENT = 'UPSET_EVENT';
export const UPSET_EVENT_RESPONSE = 'UPSET_EVENT_RESPONSE';

// FAQ
export const SET_FAQ_ITEMS = 'SET_FAQ_ITEMS';

// Invitation
export const SET_INVITATION_ERROR = 'SET_INVITATION_ERROR';
export const SET_INVITATION_LOADING = 'SET_INVITATION_LOADING';
export const SET_INVITATION_DATA = 'SET_INVITATION_DATA';

// UI
export const SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT';

// Router
export const SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE';
export const SET_ROUTES = 'SET_ROUTES';
export const SET_PREV_ROUTE = 'SET_PREV_ROUTE';
