/**
 * External dependencies
 */
import { FC, useEffect, useState } from 'react';

/**
 * Internal dependencies
 */
import { EventIntro } from '@/event';
import { EventInvites } from '@/types';
import { differenceInHours, differenceInMinutes } from 'date-fns';
import { EventIntroProps } from '@/event/components/EventIntro/EventIntro';

type CreateEventBoxProps = {
	invite: EventInvites;
} & Pick<EventIntroProps, 'onCancel' | 'onContinue'>;

const CreateEventBox: FC<CreateEventBoxProps> = ({ invite, ...props }) => {
	const [remainingHours, setRemainingHours] = useState(
		differenceInHours(new Date(invite.validUntil), Date.now())
	);

	useEffect(() => {
		const validUntil = new Date(invite.validUntil);

		setTimeout(
			() => setRemainingHours(differenceInHours(validUntil, Date.now())),
			((differenceInMinutes(validUntil, Date.now()) % 60) + 1) * 1000
		);
	}, [invite]);

	const isPending = invite.status === 'pending';

	return (
		<EventIntro
			content={
				<>
					{isPending && (
						<p>
							Get creative! Surprise and challenge others with a
							new event.
						</p>
					)}
					<p>
						You have {remainingHours} hours to create an instruction
						for a quick performative action. You can write it
						yourself or generate a task for the participants.
					</p>
				</>
			}
			title={
				isPending
					? ['You’ve been chosen', 'to start a new event!']
					: ['Finish your', 'event settings!']
			}
			cancelLabel="Pass it on"
			continueLabel={isPending ? 'Accept' : 'Go back to Event Settings'}
			{...props}
		/>
	);
};

export default CreateEventBox;
