/**
 * External dependencies
 */
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { HalfScreenLayout } from '@/layouts';
import SignUpProgress from '../SignUpProgress';

const SignUpLayout: FC = () => (
	<HalfScreenLayout beforeContent={<SignUpProgress />}>
		<Outlet />
	</HalfScreenLayout>
);

export default SignUpLayout;
