// TODO: Import from sass variables when possible (@see https://github.com/vercel/next.js/issues/73207)
const breakpoints = {
	xs: 384,
	sm: 576,
	md: 768,
	lg: 960,
	xl: 1152,
};

export default breakpoints;
