/**
 * Exteral dependencies
 */
import { useCallback } from 'react';

/**
 * Internal dependencies
 */
import { useHandleAuthAction } from '..';
import { usePocketBase } from '@/pocketbase';

const useLoginWithProvider = () => {
	const pb = usePocketBase();
	const handleAction = useHandleAuthAction();

	return useCallback(
		(provider: string) =>
			handleAction(() =>
				pb.collection('users').authWithOAuth2({ provider })
			),
		[handleAction, pb]
	);
};

export default useLoginWithProvider;
