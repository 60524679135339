/**
 * External dependencies
 */
import { FC } from 'react';
import { Flex as BaseFlex, FlexProps } from 'antd';

const Flex: FC<FlexProps> = ({ gap, ...props }) => (
	<BaseFlex
		gap={typeof gap === 'number' ? `${gap / 16}em` : gap}
		{...props}
	/>
);

export default Flex;
