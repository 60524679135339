/**
 * External dependencies
 */
import { Form as BaseForm } from 'antd';
import { ReactNode } from 'react';

/**
 * Internal dependencies
 */
import classes from './Form.module.scss';
import { Error, Success } from '@/components';
import { FormProps } from '@/forms/types';

const requiredMarkFn = (
	label: ReactNode,
	{ required }: { required: boolean }
) => (
	<>
		{label}
		{required && <span className={classes.requiredMark}>*</span>}
	</>
);

const Form: typeof BaseForm = <V extends any = any>({
	error,
	processing,
	requiredMark = requiredMarkFn,
	success,
	...props
}: FormProps<V>) => (
	<>
		<Error>{error}</Error>
		<Success>{success}</Success>
		<BaseForm
			disabled={processing}
			requiredMark={requiredMark}
			{...props}
		/>
	</>
);

Form.create = BaseForm.create;
Form.ErrorList = BaseForm.ErrorList;
Form.Item = BaseForm.Item;
Form.List = BaseForm.List;
Form.Provider = BaseForm.Provider;
Form.useForm = BaseForm.useForm;
Form.useFormInstance = BaseForm.useFormInstance;
Form.useWatch = BaseForm.useWatch;

export default Form;
