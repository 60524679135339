/**
 * External dependencies
 */
import { getCountryData, TCountryCode } from 'countries-list';
import { parseISO } from 'date-fns';

/**
 * Internal dependencies
 */
import { UserProfiles } from '@/types';
import { UserProfileModel } from '@/user';
import { getFileUrl } from '@/pocketbase';

const getProfileModel = ({
	avatar,
	location,
	created,
	updated,
	...profile
}: UserProfiles): UserProfileModel => ({
	...profile,
	name:
		[profile.firstName, profile.lastName].filter((v) => !!v).join(' ') ||
		profile.username,
	avatar: !!avatar ? getFileUrl(profile, avatar) : undefined,
	location: !!location ? getCountryData(location as TCountryCode) : undefined,
	created: parseISO(created).getTime(),
	updated: parseISO(updated).getTime(),
});

export default getProfileModel;
