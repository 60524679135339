/**
 * External dependencies
 */
import { FC, useEffect, useState } from 'react';
import { Flex } from 'antd';
import { format, getUnixTime } from 'date-fns';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { Progress, Text } from '@/components';
import classes from './DateProgress.module.scss';

type DateProgressProps = {
	className?: string;
	from: string | number | Date;
	to: string | number | Date;
};

const cx = classNames.bind(classes);

const DateProgress: FC<DateProgressProps> = ({ className, from, to }) => {
	const [now, setNow] = useState(getUnixTime(new Date()));

	const fromTime = getUnixTime(from);
	const toTime = getUnixTime(to);

	const percent = Math.max(
		0,
		Math.min(100, ((now - fromTime) / (toTime - fromTime)) * 100)
	);

	useEffect(() => {
		if (percent >= 100) {
			return;
		}

		setTimeout(() => setNow(getUnixTime(new Date())), 1000);
	}, [percent]);

	return (
		<Flex className={cx(className, 'wrap')} gap={10} align="center">
			<Text className={cx('date')}>{format(from, 'MMM d')}</Text>
			<Progress
				className={cx('progress')}
				percent={percent}
				size={{ height: 11 }}
			/>
			<Text className={classes.date}>{format(to, 'MMM d')}</Text>
		</Flex>
	);
};

export default DateProgress;
