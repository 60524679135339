/**
 * External dependencies
 */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { EventModel } from '@/event/types';
import { getEventUrl } from '@/event/utils';
import { useCatch } from '@/hooks';
import { usePocketBase } from '@/pocketbase';
import { isFuture } from 'date-fns';

const useJoinEvent = (event: EventModel | null) => {
	const catchError = useCatch();
	const navigate = useNavigate();
	const pb = usePocketBase();

	const shouldJoin = !!event && !event.hasJoined && isFuture(event.endsAt);

	useEffect(() => {
		if (shouldJoin) {
			catchError(async () => {
				if (!event) {
					return;
				}

				if (!event.hasJoined) {
					await pb.send(`/events/${event.id}/join`, {
						method: 'POST',
					});
				}

				navigate(getEventUrl(event));
			});
		}
	}, [catchError, event, navigate, pb, shouldJoin]);
};

export default useJoinEvent;
