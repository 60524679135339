/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { Text } from '@/components';
import classes from './Url.module.scss';

type UrlProps = {
	children: string;
};

const Url: FC<UrlProps> = ({ children }) => {
	const [protocol, rest] = children.split('://');

	return (
		<Text>
			{rest && <Text className={classes.protocol}>{protocol}://</Text>}
			{rest}
		</Text>
	);
};

export default Url;
