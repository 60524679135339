/**
 * Internal dependencies
 */
import { useInvitation } from '@/invitation';

const useCanCreateEvent = () => {
	const [invitation, { error }] = useInvitation();

	return invitation && invitation?.status !== 'rejected'
		? true
		: error
			? false
			: null;
};

export default useCanCreateEvent;
