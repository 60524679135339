/**
 * External dependencies
 */
import { Button, ButtonProps } from 'antd';
import { forwardRef } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import classes from './FlatButton.module.scss';

const cx = classNames.bind(classes);

const FlatButton = forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, ...props }, ref) => (
		<Button className={cx('button', className)} ref={ref} {...props} />
	)
);

FlatButton.displayName = 'FlatButton';

export default FlatButton;
