/**
 * External dependencies
 */
import { useEffect } from 'react';

/**
 * Internal dependencies
 */
import { setEventActivities, useDispatch } from '@/store';
import { usePocketBaseCall } from '@/hooks';
import useCurrentEvent from '../useCurrentEvent';

const useEventActivitiesSync = () => {
	const { event, responses } = useCurrentEvent();
	const { pocketBaseCall } = usePocketBaseCall();
	const dispatch = useDispatch();

	const responsesJson = JSON.stringify(responses);

	useEffect(
		() => () => {
			dispatch(setEventActivities(null));
		},
		[dispatch, event]
	);

	useEffect(() => {
		if (!event?.id) {
			return;
		}

		pocketBaseCall(async (pb) => {
			dispatch(
				setEventActivities(
					await pb.collection('activities').getFullList({
						sort: '-createdAt',
						filter: `event = "${event.id}"`,
					})
				)
			);
		});
	}, [dispatch, event?.id, pocketBaseCall, responsesJson]);
};

export default useEventActivitiesSync;
