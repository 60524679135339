/**
 * External dependencies
 */
import { FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { Avatar, UserProfileModel } from '@/user';
import { DualFontText, Paragraph, Title } from '@/components';
import { ReactComponent as EnvelopeIcon } from '@/images/icon-envelope-alt.svg';
import { ReactComponent as PinIcon } from '@/images/icon-pin.svg';
import { ReactComponent as UserIcon } from '@/images/icon-user.svg';
import classes from './UserProfile.module.scss';

type UserProfileProps = {
	profile: UserProfileModel;
};

const cx = classNames.bind(classes);

const UserProfile: FC<UserProfileProps> = ({
	profile: { avatar, bio, email, location, name, username },
}) => (
	<div className={cx('content')}>
		<div className={cx('column')}>
			<Avatar className={cx('avatar')} src={avatar} size={140} />
			<DualFontText
				parts={["Hey, I'm", `${name}!`]}
				postClassName={cx('username')}
				preClassName={cx('greet')}
				size="large"
			/>
		</div>
		<div className={cx('details')}>
			<Title className={cx('bio-title')} level={3}>
				Know me better
			</Title>
			<Paragraph className={cx('bio')}>{bio}</Paragraph>
			<ul className={cx('contact')}>
				<li className={cx('contact-item')}>
					<span className={cx('contact-icon')}>
						<UserIcon />
					</span>
					{`@${username}`}
				</li>
				{email && (
					<li className={cx('contact-item')}>
						<span className={cx('contact-icon')}>
							<EnvelopeIcon />
						</span>
						{email}
					</li>
				)}
				{location && (
					<li className={cx('contact-item')}>
						<span className={cx('contact-icon')}>
							<PinIcon />
						</span>
						{location.name}
					</li>
				)}
			</ul>
		</div>
	</div>
);

export default UserProfile;
