/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { SidebarLayout } from '@/layouts';
import {
	SupportContent,
	useContactFormController,
	useFaqItems,
} from '@/support';

const SupportPage: FC = () => (
	<SidebarLayout>
		<SupportContent items={useFaqItems()} {...useContactFormController()} />
	</SidebarLayout>
);

export default SupportPage;
