/**
 * Internal dependencies
 */
import { ActivityData } from '@/event/components/EventActivity/types';
import { EventActivityModel, EventModel } from '@/event/types';
import { pb } from '@/pocketbase';

export const getActivityData = (
	currentEvent: EventModel,
	{
		type,
		createdAt,
		user: userId,
		responseAuthor: responseAuthorId,
	}: EventActivityModel
): ActivityData | undefined => {
	const user = currentEvent.attendees.find(
		(attendee) => attendee.id === userId
	);

	if (!user) {
		return;
	}

	const responseAuthor = currentEvent.attendees.find(
		(attendee) => attendee.id === responseAuthorId
	);

	return {
		createdAt,
		user: {
			...user,
			name: user.id === pb.authStore.record?.id ? 'You' : user.name,
		},
		...(type === 'reaction'
			? {
					action: type,
					author: {
						...responseAuthor!,
						name:
							responseAuthor?.id === pb.authStore.record?.id
								? 'You'
								: responseAuthor!.name,
					},
				}
			: {
					action: type,
					message: 'posted an answer',
				}),
	};
};

export const isActivityData = (
	activity: ActivityData | undefined
): activity is ActivityData => Boolean(activity);
