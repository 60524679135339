/**
 * External dependencies
 */
import { MutableRefObject, useRef } from 'react';

const useValueRef = <T>(value: T): MutableRefObject<T> => {
	const valueRef = useRef(value);
	valueRef.current = value;

	return valueRef;
};

export default useValueRef;
