/**
 * External dependencies
 */
import { useEffect } from 'react';

/**
 * Internal dependencies
 */
import {
	removeEventResponse,
	setEventResponses,
	setEventResponsesError,
	setEventResponsesLoading,
	upsetEventResponse,
	useDispatch,
} from '@/store';
import { usePocketBase } from '@/pocketbase';
import { getEventResponseModel } from '@/event/utils';

const useEventResponsesSync = (eventId: string | undefined) => {
	const pb = usePocketBase();
	const dispatch = useDispatch();

	useEffect(() => {
		if (!eventId) {
			return;
		}

		dispatch(setEventResponsesLoading(true));

		const collection = pb.collection('event_responses');
		const subscribeTopic = '*';
		const options: Parameters<typeof collection.getFullList>[0] = {
			expand: [
				{ key: 'author' },
				{ key: 'event_reactions_via_response' },
			],
			filter: `event = "${eventId}"`,
			sort: 'createdAt',
		};

		collection
			.getFullList(options)
			.then((responses) =>
				dispatch(
					setEventResponses(responses.map(getEventResponseModel))
				)
			)
			.catch(() => dispatch(setEventResponsesError(true)))
			.finally(() => dispatch(setEventResponsesLoading(false)));

		collection.subscribe(
			subscribeTopic,
			({ action, record }) => {
				dispatch(
					(action === 'delete'
						? removeEventResponse
						: upsetEventResponse)(getEventResponseModel(record))
				);
			},
			options
		);

		return () => {
			collection.unsubscribe(subscribeTopic);
			dispatch(setEventResponses([]));
		};
	}, [dispatch, eventId, pb]);
};

export default useEventResponsesSync;
