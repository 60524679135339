/**
 * External dependencies
 */
import { FC } from 'react';
import { Link } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { EmailPasswordForm, FormHeader } from '@/forms';
import { HalfScreenLayout } from '@/layouts';
import { Route } from '@/router';
import { usePasswordResetFormController } from '@/sing-in';
import { Column } from '@/components';

type ForgotPasswordPageProps = {};

const ForgotPasswordPage: FC<ForgotPasswordPageProps> = () => {
	const { subtitle, status, title, ...props } =
		usePasswordResetFormController();

	return (
		<HalfScreenLayout>
			{status ? (
				<Column align="center">
					<FormHeader centered {...{ subtitle, title }} />
					{status === 'email-sent' ? (
						<p>
							Check your email for a link to reset your password.
						</p>
					) : (
						<p>Your password has been changed.</p>
					)}
					<Link to={Route.SignIn}>
						<strong>Back to sign in</strong>
					</Link>
				</Column>
			) : (
				<EmailPasswordForm
					{...props}
					footer={
						<Link to={Route.SignIn}>
							<strong>Back to sign in</strong>
						</Link>
					}
					subtitle={subtitle}
					title={title}
					withSocialButtons={false}
				/>
			)}
		</HalfScreenLayout>
	);
};

export default ForgotPasswordPage;
