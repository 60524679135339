/**
 * External dependencies
 */
import { Form } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { Button, Flex } from '@/components';
import { Checkbox, EmailPasswordForm } from '@/forms';
import { HalfScreenLayout } from '@/layouts';
import { ReactComponent as ChevronRight } from '@/images/chevron-right.svg';
import { Route } from '@/router';
import { useSignInFormController } from '@/sing-in';

export type SignInPageFields = {
	remember: boolean;
};

const SignInPage: FC = () => (
	<HalfScreenLayout>
		<EmailPasswordForm
			{...useSignInFormController()}
			afterFields={
				<Flex justify="space-between">
					<Form.Item name="remember" valuePropName="checked" noStyle>
						<Checkbox size="large">Remember me</Checkbox>
					</Form.Item>
					<Button
						href={Route.PasswordReset}
						icon={<ChevronRight />}
						iconPosition="end"
						variant="link"
					>
						Forgot password?
					</Button>
				</Flex>
			}
			buttonLabel="Sign In"
			footer={
				<>
					Don’t have an account?{' '}
					<Link to={Route.SignUp}>
						<strong>Sign Up</strong>
					</Link>
				</>
			}
			subtitle="Select method to log in to your Account:"
			title={['Welcome ', 'Back!']}
		/>
	</HalfScreenLayout>
);

export default SignInPage;
