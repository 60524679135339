/**
 * External dependencies
 */
import { FC, MouseEventHandler, ReactNode } from 'react';
import { Flex } from 'antd';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { FlatButton, Text } from '@/components';
import { RenderFunction } from 'antd/es/_util/getRenderPropValue';
import classes from './ModalHeader.module.scss';
import { ReactComponent as Close } from '@/images/close.svg';

const cx = classNames.bind(classes);

type ModalHeaderProps = {
	title?: ReactNode | RenderFunction;
	onClose?: MouseEventHandler<HTMLButtonElement>;
};

const ModalHeader: FC<ModalHeaderProps> = ({ onClose, title }) => (
	<Flex justify="space-between" align="center">
		<Text className={cx('title')}>
			{typeof title === 'function' ? title() : title}
		</Text>
		<FlatButton
			icon={<Close />}
			className={cx('close')}
			onClick={onClose}
		/>
	</Flex>
);
export default ModalHeader;
