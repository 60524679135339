/**
 * External dependencies
 */
import { Collapse } from 'antd';
import { FC, PropsWithChildren } from 'react';

/**
 * Internal dependencies
 */
import { ReactComponent as ChevronDown } from '@/images/chevron-down.svg';
import classes from './Widget.module.scss';
import WhiteBox from '../WhiteBox';

type WidgetProps = PropsWithChildren<{
	title: string;
}>;

const Widget: FC<WidgetProps> = ({ children, title }) => (
	<WhiteBox padding={false}>
		<Collapse
			className={classes.collapse}
			defaultActiveKey={0}
			expandIconPosition="end"
			expandIcon={(props) => (
				<ChevronDown
					style={{
						transform: props.isActive
							? 'rotate(0)'
							: 'rotate(-90deg)',
					}}
				/>
			)}
			ghost
			items={[
				{
					label: title,
					children,
				},
			]}
		/>
	</WhiteBox>
);

export default Widget;
