/**
 * External dependencies
 */
import { FC } from 'react';
import { FormItemProps } from 'antd';

/**
 * Internal dependencies
 */
import { CheckableFormItem } from '@/forms';

const PersonalInfoFormItem: FC<FormItemProps> = ({ name, ...props }) => (
	<CheckableFormItem
		checkboxLabel="Show on profile"
		checkboxName={`${name}ShowOnProfile`}
		name={name}
		{...props}
	/>
);

export default PersonalInfoFormItem;
