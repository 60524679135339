/**
 * External dependencies
 */
import { RefObject, useCallback, useEffect } from 'react';

/**
 * Internal dependencies
 */
import { setHeaderHeight, useDispatch, useSelector } from '@/store';
import { useWindowEvent } from '@/hooks';

const useHeaderHeightObserver = <T extends HTMLElement>(ref: RefObject<T>) => {
	const dispatch = useDispatch();
	const height = useSelector(({ ui }) => ui.headerHeight);

	const handleHeight = useCallback(
		() =>
			setTimeout(
				() =>
					typeof ref.current?.offsetHeight === 'number' &&
					ref.current.offsetHeight !== height &&
					dispatch(setHeaderHeight(ref.current.offsetHeight))
			) && void 0,
		// eslint-disable-next-line @grncdr/react-hooks/exhaustive-deps
		[dispatch, height]
	);

	useEffect(() => handleHeight(), [handleHeight]);
	useWindowEvent('resize', handleHeight);
};

export default useHeaderHeightObserver;
