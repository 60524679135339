/**
 * Exteral dependencies
 */
import { useCallback } from 'react';

/**
 * Internal dependencies
 */
import { RegisterFields, useLogin } from '@/auth';
import { useHandleAuthAction } from '..';
import { usePocketBase } from '@/pocketbase';
import { useSelector } from '@/store';

const useRegister = () => {
	const handleAction = useHandleAuthAction();
	const login = useLogin();
	const pb = usePocketBase();
	const referrer = useSelector(({ auth }) => auth.referrer);

	return useCallback(
		(fields: RegisterFields) =>
			handleAction(async () => {
				await pb
					.collection('users')
					.create({ ...fields, name: fields.email, referrer });

				return login(fields);
			}),
		[handleAction, login, pb, referrer]
	);
};

export default useRegister;
