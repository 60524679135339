/**
 * External dependencies
 */
import { FC, PropsWithChildren } from 'react';

/**
 * Internal dependencies
 */
import { Show } from '@/components';
import { useUserRedirect } from '@/user/hooks';

const UserGuard: FC<PropsWithChildren> = ({ children }) => (
	<Show when={!useUserRedirect()}>{children}</Show>
);

export default UserGuard;
