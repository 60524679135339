/**
 * External dependencies
 */
import { FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { AvatarGroup, UserData } from '@/user';
import ParticipantsCount from '../ParticipantsCount';
import classes from './Participants.module.scss';
import { Flex, Text } from '@/components';

const cx = classNames.bind(classes);

type ParticipantsProps = {
	className?: string;
	layout?: 'vertical' | 'horizontal';
	participants: UserData[];
	showLabel?: boolean;
	showMoreCount?: boolean;
	size?: 'default' | 'large';
};

const Participants: FC<ParticipantsProps> = ({
	className,
	layout = 'horizontal',
	participants,
	showLabel = layout === 'vertical',
	showMoreCount = layout === 'vertical',
	size = layout === 'vertical' ? 'large' : 'default',
}) => (
	<Flex className={cx('participants', layout, className)}>
		<AvatarGroup
			avatars={participants.map((participant) => participant.avatar)}
			showMoreCount={showMoreCount}
			size={size}
		/>
		<Flex className={cx('count')} justify="space-between" align="center">
			{showLabel && <Text className={cx('label')}>Participants:</Text>}
			<ParticipantsCount count={participants.length} />
		</Flex>
	</Flex>
);

export default Participants;
