/**
 * External dependencies
 */
import { FC } from 'react';
import { UploadFile } from 'antd/es/upload';

/**
 * Internal dependencies
 */
import { Form, FormProps, SubmitButton } from '@/forms';
import AnswerInput from '../AnswerInput';
import classes from './AnswerForm.module.scss';

export type AnswerFormFields = {
	content?: string;
	files?: UploadFile[];
};

export type AnswerFormProps = FormProps<AnswerFormFields>;

const AnswerForm: FC<AnswerFormProps> = ({ rootClassName, ...props }) => (
	<div className={rootClassName}>
		<Form {...props} className={classes.form}>
			<AnswerInput />
			<SubmitButton hasArrow className={classes.button}>
				Send your response
			</SubmitButton>
		</Form>
	</div>
);

export default AnswerForm;
