/**
 * External dependencies
 */
import { forwardRef } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { Button } from '@/components';
import { ButtonProps } from '@/components/Button/Button';
import { ReactComponent as ChevronRight } from '@/images/chevron-right-large.svg';
import classes from './SubmitButton.module.scss';

const cx = classNames.bind(classes);

type SubmitButtonProps = ButtonProps & {
	hasArrow?: boolean;
};

const SubmitButton = forwardRef<HTMLButtonElement, SubmitButtonProps>(
	({ className, hasArrow, ...props }, ref) => (
		<Button
			className={cx('button', className)}
			ref={ref}
			htmlType="submit"
			icon={hasArrow ? <ChevronRight /> : undefined}
			size="large"
			{...props}
		/>
	)
);

export default SubmitButton;
