/**
 * External dependencies
 */
import { FC } from 'react';
import {
	Col as AntCol,
	Form,
	Row as AntRow,
	ColProps,
	RowProps,
	ConfigProvider,
} from 'antd';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { CountrySelect, Input, Textarea } from '@/forms';
import { Title } from '@/components';
import PersonalInfoFormItem from '../PersonalInfoFormItem';
import classes from './PersonalInfoFields.module.scss';

const cx = classNames.bind(classes);

type PersonalInfoFieldsProps = {
	className?: string;
};

const Col: FC<ColProps> = (props) => <AntCol span={24} sm={12} {...props} />;
const Row: FC<RowProps> = (props) => <AntRow gutter={20} {...props} />;

const PersonalInfoFields: FC<PersonalInfoFieldsProps> = ({ className }) => (
	<ConfigProvider
		theme={{
			token: {
				screenSM: 448,
				screenSMMin: 448,
				screenXSMax: 447,
				screenXS: 384,
				screenXSMin: 384,
			},
		}}
	>
		<div className={cx('wrap', className)}>
			<Title className={classes.heading} level={2}>
				Personal Informations
			</Title>
			<Row>
				<Col>
					<PersonalInfoFormItem label="First Name" name="firstName">
						<Input />
					</PersonalInfoFormItem>
				</Col>
				<Col>
					<PersonalInfoFormItem label="Last Name" name="lastName">
						<Input />
					</PersonalInfoFormItem>
				</Col>
			</Row>
			<Row>
				<Col>
					<PersonalInfoFormItem label="Email" name="email">
						<Input disabled />
					</PersonalInfoFormItem>
				</Col>
				<Col>
					<PersonalInfoFormItem label="Location" name="location">
						<CountrySelect />
					</PersonalInfoFormItem>
				</Col>
			</Row>
			<Row>
				<AntCol span={24}>
					<Form.Item label="Know Me Better" name="bio">
						<Textarea placeholder="Enter description about you..." />
					</Form.Item>
				</AntCol>
			</Row>
		</div>
	</ConfigProvider>
);

export default PersonalInfoFields;
