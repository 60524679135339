/**
 * External dependencies
 */
import { useEffect, useState } from 'react';

/**
 * Internal dependencies
 */
import { UserProfiles } from '@/types';
import { usePocketBaseCall } from '@/hooks';

const useUserProfile = (username: string) => {
	const { error, pocketBaseCall } = usePocketBaseCall();
	const [profile, setProfile] = useState<UserProfiles | null>(null);

	useEffect(() => {
		pocketBaseCall(async (pb) => {
			setProfile(
				await pb
					.collection('user_profiles')
					.getFirstListItem(`username="${username}"`)
			);
		});
	}, [pocketBaseCall, username]);

	return {
		error,
		profile,
	};
};

export default useUserProfile;
