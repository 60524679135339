/**
 * External dependencies
 */
import { FC, useState } from 'react';

/**
 * Internal dependencies
 */
import { BackButtonLayout } from '@/layouts';
import { Button } from '@/components';
import { CreateEventForm, CreateEventGuard, EventTipsModal } from '@/event';
import { ReactComponent as BulbIcon } from '@/images/bulb.svg';
import { useCreateEventController } from '@/event/hooks';

const CreateEventPage: FC = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<CreateEventGuard>
			<BackButtonLayout
				title="Set up your Event"
				mobileRightArea={
					<Button
						color="secondary"
						icon={<BulbIcon />}
						iconPosition="start"
						size="large"
						variant="link"
						onClick={() => setIsModalOpen(true)}
					>
						Tips
					</Button>
				}
			>
				<CreateEventForm
					onTipsOpen={() => setIsModalOpen(true)}
					{...useCreateEventController()}
				/>
			</BackButtonLayout>
			<EventTipsModal
				open={isModalOpen}
				onCancel={() => setIsModalOpen(false)}
				width="auto"
			/>
		</CreateEventGuard>
	);
};

export default CreateEventPage;
