/**
 * External dependencies
 */
import { parseISO } from 'date-fns';

/**
 * Internal dependencies
 */
import { getFileUrl } from '@/pocketbase';
import { UserModel } from '@/user';
import { Users } from '@/types';

const getUserModel = ({
	avatar,
	created,
	updated,
	profileFields,
	...user
}: Users): UserModel => ({
	...user,
	avatar: getFileUrl(user, avatar),
	created: parseISO(created).getTime(),
	name:
		(Array.isArray(profileFields) &&
			[
				profileFields.includes('firstName') && user.firstName,
				profileFields.includes('lastName') && user.lastName,
			]
				.filter(Boolean)
				.join(' ')) ||
		user.username,
	profileFields: Array.isArray(profileFields) ? profileFields : [],
	updated: parseISO(updated).getTime(),
});

export default getUserModel;
