/**
 * Internal dependencies
 */
import { useSelector } from '@/store';

const useEvents = () =>
	useSelector(({ events: { data, ...events } }) => ({
		...events,
		events: data,
	}));

export default useEvents;
