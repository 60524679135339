/**
 * External dependencies
 */
import { Form, FormItemProps } from 'antd';
import { FC, PropsWithChildren } from 'react';

/**
 * Internal dependencies
 */
import { useRequiredCheckboxValidator } from '@/forms/hooks';
import Checkbox from '../Checkbox';

type RequiredCheckboxFormFieldProps = Omit<FormItemProps, 'children'> &
	PropsWithChildren<{
		errorMessage?: string;
	}>;

const RequiredCheckboxFormField: FC<RequiredCheckboxFormFieldProps> = ({
	children,
	errorMessage,
	...props
}) => {
	const { validator, ...checkboxProps } =
		useRequiredCheckboxValidator(errorMessage);

	return (
		<Form.Item {...props} rules={[{ validator }]}>
			<Checkbox size="large" {...checkboxProps}>
				{children}
			</Checkbox>
		</Form.Item>
	);
};

export default RequiredCheckboxFormField;
