/**
 * External dependencies
 */
import { FC, HTMLAttributes } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import classes from './WhiteBox.module.scss';

const cx = classNames.bind(classes);

type WhiteBoxProps = HTMLAttributes<HTMLDivElement> & {
	padding?: 'small' | 'large' | false;
};

const WhiteBox: FC<WhiteBoxProps> = ({
	className,
	padding = 'large',
	...props
}) => (
	<div
		className={cx('box', className, {
			[`padding-${padding}`]: padding,
		})}
		{...props}
	/>
);

export default WhiteBox;
