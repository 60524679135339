/**
 * External dependencies
 */
import { FC, ReactNode } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { DualFontText, WhiteBox } from '@/components';
import classes from './EventIntro.module.scss';
import Actions from './Actions';

export type EventIntroProps = {
	cancelLabel?: string;
	className?: string;
	content: ReactNode;
	continueLabel?: string;
	onCancel?: () => void;
	onContinue?: () => void;
	title: [string, string];
};

const cx = classNames.bind(classes);

const EventIntro: FC<EventIntroProps> = ({
	cancelLabel,
	className,
	content,
	continueLabel,
	onCancel,
	onContinue,
	title,
}) => (
	<WhiteBox className={cx('wrap', className)}>
		<div className={cx('inner')}>
			<DualFontText
				parts={title}
				preClassName={cx('title')}
				postClassName={cx('subtitle')}
			/>
			<div className={cx('content')}>{content}</div>
			<Actions
				{...{ cancelLabel, continueLabel, onCancel, onContinue }}
			/>
		</div>
	</WhiteBox>
);

export default EventIntro;
