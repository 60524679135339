/**
 * External dependencies
 */
import { FC, PropsWithChildren, ReactNode } from 'react';
import { Flex } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { Button, Paragraph } from '@/components';
import { ReactComponent as ChevronLeft } from '@/images/chevron-left.svg';
import { Route } from '@/router';
import { useSelector } from '@/store';
import AppLayout from '../AppLayout';
import classes from './BackButtonLayout.module.scss';

const cx = classNames.bind(classes);

type BackButtonLayoutProps = PropsWithChildren<{
	backButtonLabel?: string;
	backUrl?: string;
	contentClassName?: string;
	mobileRightArea?: ReactNode;
	title?: string;
}>;

const BackButtonLayout: FC<BackButtonLayoutProps> = ({
	backButtonLabel = 'Go back to the list',
	backUrl,
	children,
	contentClassName,
	mobileRightArea,
	title,
}) => {
	const navigate = useNavigate();
	const path = useLocation().pathname;
	const prevRoute = useSelector(({ router }) => router.prevRoute);

	return (
		<AppLayout>
			<Flex className={classes.row} justify="space-between">
				<div className={classes.side}>
					<Button
						className={classes.button}
						color="neutral"
						icon={<ChevronLeft />}
						iconPosition="start"
						variant="link"
						onClick={(e) => {
							e.preventDefault();

							if (!backUrl && window.history.state.idx > 0) {
								navigate(-1);
								return;
							}

							const goBackTo = backUrl ?? prevRoute;

							navigate(
								goBackTo && goBackTo !== path
									? goBackTo
									: Route.Home
							);
						}}
					>
						{backButtonLabel}
					</Button>
				</div>
				{title && (
					<>
						<Paragraph className={classes.title}>{title}</Paragraph>
						<div className={classes.side}>
							{mobileRightArea && (
								<Flex className={classes.mobile} justify="end">
									{mobileRightArea}
								</Flex>
							)}
						</div>
					</>
				)}
			</Flex>
			<div className={cx('content', contentClassName)}>{children}</div>
		</AppLayout>
	);
};

export default BackButtonLayout;
