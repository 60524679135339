/**
 * External dependencies
 */
import { FC, PropsWithChildren } from 'react';

/**
 * Internal dependencies
 */
import { PocketBaseContext } from '@/pocketbase/context';
import { pb } from '@/pocketbase';

const PocketBaseProvider: FC<PropsWithChildren> = ({ children }) => (
	<PocketBaseContext.Provider value={pb}>
		{children}
	</PocketBaseContext.Provider>
);

export default PocketBaseProvider;
