/**
 * External dependencies
 */
import { Divider } from 'antd';
import { ReactNode } from 'react';

/**
 * Internal dependencies
 */
import { EmailFormItem, PasswordFormFields, TitledForm } from '@/forms';
import { SocialLoginButtons } from '@/sing-in';
import { SocialLoginButtonsProps } from '@/sing-in/components/SocialLoginButtons/SocialLoginButtons';
import { TitledFormProps } from '../TitledForm/TitledForm';

export type EmailPasswordFormFields<C = false, P = false, E = false> = {
	email: E extends true ? string : string | undefined;
	password: P extends true ? string : string | undefined;
	passwordConfirm: C extends true ? string : string | undefined;
};

export type EmailPasswordFormProps<
	T extends {} = {},
	C extends boolean = boolean,
	P extends boolean = boolean,
	E extends boolean = boolean,
> = TitledFormProps<EmailPasswordFormFields<C, P, E> & T> & {
	afterFields?: ReactNode;
	onSocialButtonClick?: SocialLoginButtonsProps['onClick'];
	withEmail?: boolean;
	withPassword?: boolean;
	withPasswordConfirmation?: boolean;
	withSocialButtons?: boolean;
};

const EmailPasswordForm = <T extends {}, Confirm extends boolean>({
	afterFields,
	onSocialButtonClick,
	withEmail = true,
	withPassword = true,
	withPasswordConfirmation,
	withSocialButtons = true,
	...props
}: EmailPasswordFormProps<T, Confirm>): ReactNode => (
	<TitledForm
		beforeForm={
			withSocialButtons ? (
				<>
					<SocialLoginButtons onClick={onSocialButtonClick} />
					<Divider>or continue with email</Divider>
				</>
			) : null
		}
		{...props}
	>
		{withEmail && <EmailFormItem />}
		{withPassword && (
			<PasswordFormFields withConfirmation={withPasswordConfirmation} />
		)}
		{afterFields}
	</TitledForm>
);

export default EmailPasswordForm;
