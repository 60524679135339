/**
 * External dependencies
 */
import { Reducer } from 'redux';

/**
 * Internal dependencies
 */
import { setHeaderHeight } from '@/store/actions';
import initialState from '../../initialState';
import isAction from '../../isAction';

const headerHeight: Reducer<number> = (
	state = initialState.ui.headerHeight,
	action
) => (isAction(action, setHeaderHeight) ? action.payload : state);

export default headerHeight;
