/**
 * External dependencies
 */
import { Reducer } from 'redux';

/**
 * Internal dependencies
 */
import { Route } from '@/router';
import { setCurrentRoute, setRoutes } from '@/store/actions';
import initialState from '../../initialState';
import isAction from '../../isAction';

const currentRoute: Reducer<Route | null> = (
	state = initialState.router.currentRoute,
	action
) =>
	isAction(action, setCurrentRoute)
		? action.payload
		: isAction(action, setRoutes)
			? (action.payload.current ?? state)
			: state;

export default currentRoute;
