/**
 * External dependencies
 */
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { EventsSync } from '@/event';
import { InvitationHandler } from '@/invitation';
import { MainLayout } from '@/layouts';
import { RouteWatcher } from '@/router';
import { UserGuard } from '@/user';

const Main: FC = () => (
	<UserGuard>
		<InvitationHandler />
		<EventsSync />
		<MainLayout>
			<RouteWatcher />
			<Outlet />
		</MainLayout>
	</UserGuard>
);

export default Main;
