/**
 * External dependencies
 */
import { FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import classes from './Copyable.module.scss';
import { ReactComponent as CopyIcon } from '@/images/icon-copy.svg';
import { Text } from '@/components';
import { TextProps } from 'antd/es/typography/Text';

const cx = classNames.bind(classes);

type CopyableProps = TextProps & {
	copyValue: string;
};

const Copyable: FC<CopyableProps> = ({ className, copyValue, ...props }) => (
	<Text
		{...props}
		className={cx('copyable', className)}
		copyable={{
			icon: [<CopyIcon key="copy" />],
			text: copyValue,
		}}
	/>
);

export default Copyable;
