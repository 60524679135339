/**
 * Internal dependencies
 */
import { AvatarSizeString } from '@/types';

export const avatarSize: Record<AvatarSizeString, number> = {
	default: 46,
	large: 58,
	small: 36,
};

export const avatarSizeMobile: Partial<Record<AvatarSizeString, number>> = {
	large: 36,
};

export const allowedAvatarFormats = ['gif', 'jpeg', 'png', 'webp', 'svg+xml'];
