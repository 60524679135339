/**
 * External dependencies
 */
import { FC } from 'react';
import { Popover, PopoverProps } from 'antd';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { Button } from '@/components';
import classes from './EventReactButton.module.scss';
import { ReactComponent as IconThumbsUp } from '@/images/icon-thumbs-up.svg';

type EventReactButtonProps = {
	emojis: string[];
	onReact: (emoji: string) => void;
} & PopoverProps;

const cx = classNames.bind(classes);

const EventReactButton: FC<EventReactButtonProps> = ({
	className,
	emojis,
	onReact,
	...props
}) => (
	<Popover
		{...props}
		content={
			<div className={cx('react-buttons')}>
				{emojis.map((emoji) => (
					<Button
						key={emoji}
						className={cx('react-button')}
						color="default"
						onClick={() => onReact(emoji)}
						shape="circle"
						size="small"
						variant="outlined"
					>
						{emoji}
					</Button>
				))}
			</div>
		}
		placement="bottomLeft"
		trigger="click"
	>
		<Button
			className={cx('button', className)}
			variant="link"
			iconPosition="start"
			icon={<IconThumbsUp />}
		>
			Leave your reaction
		</Button>
	</Popover>
);

export default EventReactButton;
