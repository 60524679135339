/**
 * External dependencies
 */
import { ButtonProps } from 'antd';
import { forwardRef } from 'react';
import { UserOutlined } from '@ant-design/icons';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { FlatButton, Flex } from '@/components';
import { ReactComponent as ChevronDown } from '@/images/chevron-down.svg';
import { ReactComponent as Close } from '@/images/close.svg';
import { ReactComponent as Dots } from '@/images/three-dots.svg';
import Avatar from '../Avatar';
import classes from './AccountButton.module.scss';
import Greeting from '../Greeting';

const cx = classNames.bind(classes);

type AccountButtonProps = ButtonProps & {
	active?: boolean;
	avatar?: string;
	name?: string;
};

const AccountButton = forwardRef<HTMLButtonElement, AccountButtonProps>(
	({ active, avatar, name, ...props }, ref) => (
		<FlatButton ref={ref} type="text" iconPosition="end" {...props}>
			<Flex align="center" gap={8}>
				<Avatar src={avatar} size={40} icon={<UserOutlined />} />
				{name && <Greeting>{name}</Greeting>}
				<Flex
					className={cx('icon', { active })}
					align="center"
					justify="center"
				>
					{name ? <ChevronDown /> : active ? <Close /> : <Dots />}
				</Flex>
			</Flex>
		</FlatButton>
	)
);

AccountButton.displayName = 'AccountButton';

export default AccountButton;
