/**
 * External dependencies
 */
import { FC } from 'react';
import { Divider, Form } from 'antd';

/**
 * Internal dependencies
 */
import { Copyable, Url } from '@/components';
import { defaultShareMessage } from '@/config';
import { EmailTagsInput, Textarea, TitledForm } from '@/forms';
import { ReactComponent as PaperPlaneIcon } from '@/images/icon-paper-plane.svg';
import { ShareFormFields } from '@/user/types';
import { TitledFormProps } from '@/forms/components/TitledForm/TitledForm';
import classes from './ShareForm.module.scss';

export type ShareFormProps = Omit<TitledFormProps<ShareFormFields>, 'title'> & {
	inviteLink: string;
};

const ShareForm: FC<ShareFormProps> = ({ inviteLink, ...props }) => (
	<TitledForm
		buttonIcon={<PaperPlaneIcon />}
		buttonLabel="Send invitation"
		buttonVariant="outlined"
		centered={false}
		title={['Invite your ', 'friends!']}
		initialValues={{
			message: defaultShareMessage,
		}}
		{...props}
	>
		<Form.Item label="Share Link">
			<Copyable copyValue={inviteLink}>
				<Url>{inviteLink}</Url>
			</Copyable>
		</Form.Item>
		<Divider className={classes.divider}>
			or send an invitation to email
		</Divider>
		<Form.Item
			label="Email Addresses"
			name="emails"
			rules={[{ required: true }]}
		>
			<EmailTagsInput />
		</Form.Item>
		<Form.Item
			label="Send a Message"
			name="message"
			rules={[{ required: true }]}
		>
			<Textarea size="large" />
		</Form.Item>
	</TitledForm>
);

export default ShareForm;
