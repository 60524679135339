/**
 * External dependencies
 */
import { matchPath, useLocation } from 'react-router-dom';
import { useCallback } from 'react';

/**
 * Internal dependencies
 */
import { isRoute } from '@/router/utils';
import { publicRoutes } from '@/router/config';

const useIsPublicRoute = () => {
	const isPublicRoute = useCallback(
		(route: string) =>
			(isRoute(route) && publicRoutes.includes(route)) ||
			(!isRoute(route) &&
				!!publicRoutes.find(
					(publicRoute) => !!matchPath(publicRoute, route)
				)),
		[]
	);

	return {
		isPublicRoute,
		isCurrentPublicRoute: isPublicRoute(useLocation().pathname),
	};
};

export default useIsPublicRoute;
