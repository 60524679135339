/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { EventIntro } from '@/event';

type NoEventBoxProps = {
	hasLastEvent: boolean;
	onActionClick: () => void;
};

const NoEventBox: FC<NoEventBoxProps> = ({ hasLastEvent, onActionClick }) => (
	<EventIntro
		{...(hasLastEvent && {
			continueLabel: 'Go to last event',
			onContinue: onActionClick,
		})}
		content={
			<>
				<p>
					The event will kick off as soon as it’s created and made
					available. Just hang tight, and once it's set up, you’ll be
					ready to dive in!
				</p>
				{hasLastEvent && (
					<p>
						In the meantime check out the results from the last
						event
					</p>
				)}
			</>
		}
		title={['Waiting on the go-ahead', 'to start a new event...']}
	/>
);

export default NoEventBox;
