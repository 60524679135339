/**
 * External dependencies
 */
import { ComponentProps, FC } from 'react';

/**
 * Internal dependencies
 */
import { BackButtonLayout } from '@/layouts';
import { EventPageContent, EventPageSidebar } from '@/event';
import {
	useAnswerFormController,
	useCurrentEvent,
	useCurrentEventSync,
	useHandleReaction,
	useJoinEvent,
} from '@/event/hooks';
import classes from './SingleEventPage.module.scss';

type EventPageContentProxyProps = Omit<
	ComponentProps<typeof EventPageContent>,
	'answerFormProps' | 'onReact'
>;

const EventPageContentProxy: FC<EventPageContentProxyProps> = (props) => (
	<EventPageContent
		{...props}
		answerFormProps={useAnswerFormController(props.event.id)}
		onReact={useHandleReaction()}
	/>
);

const SingleEventPage: FC = () => {
	useCurrentEventSync();

	const { event, responses } = useCurrentEvent();

	useJoinEvent(event ?? null);

	return event ? (
		<BackButtonLayout title={event.subject} contentClassName={classes.wrap}>
			<EventPageContentProxy
				className={classes.content}
				event={event}
				responses={responses}
			/>
			<EventPageSidebar className={classes.sidebar} event={event} />
		</BackButtonLayout>
	) : null;
};

export default SingleEventPage;
