/**
 * External dependencies
 */
import { Reducer } from 'redux';

/**
 * Internal dependencies
 */
import { EventResponseModel } from '@/event';
import {
	removeEventResponse,
	setEventResponses,
	upsetEventResponse,
} from '../../actions';
import initialState from '../../initialState';
import isAction from '../../isAction';

const responses: Reducer<EventResponseModel[] | null> = (
	state = initialState.currentEvent.responses,
	action
) => {
	if (isAction(action, setEventResponses)) {
		return action.payload;
	}

	if (isAction(action, upsetEventResponse)) {
		if (!state) {
			return [action.payload];
		}

		const itemIndex = state?.findIndex(
			(item) => item.id === action.payload.id
		);

		if (typeof itemIndex === 'number' && itemIndex >= 0) {
			const newState = [...state];
			newState[itemIndex] = action.payload;
			return newState;
		}

		return [...state, action.payload];
	}

	if (isAction(action, removeEventResponse)) {
		const itemIndex = state?.findIndex(
			(item) =>
				item.id ===
				(typeof action.payload === 'string'
					? action.payload
					: action.payload.id)
		);

		if (state && typeof itemIndex === 'number' && itemIndex >= 0) {
			const nextState = [...state];

			nextState.splice(itemIndex, 1);

			return nextState;
		}

		return state;
	}

	return state;
};

export default responses;
