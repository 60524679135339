/**
 * External dependencies
 */
import { useState } from 'react';

/**
 * Internal dependencies
 */
import { usePocketBase } from '@/pocketbase';
import { ContactFormProps } from '@/support/components/ContactForm/ContactForm';

const useContactFormController = (): ContactFormProps => {
	const [processing, setProcessing] = useState(false);
	const [error, setError] = useState<string>();
	const [success, setSuccess] = useState<string>();
	const pb = usePocketBase();

	return {
		onFinish: async (data) => {
			setProcessing(true);

			try {
				await pb.send('/contact', {
					body: data,
					method: 'POST',
				});

				setSuccess('Message sent.');
			} catch (error) {
				setError('Something went wrong. Please try again.');
			}

			setProcessing(false);
		},
		error,
		processing,
		success,
	};
};

export default useContactFormController;
