/**
 * Internal dependencies
 */
import { UnknownAction } from 'redux';
import { ActionCreator, Action } from './types';

const isAction = <P>(
	action: UnknownAction,
	actionCreator: ActionCreator<P>
): action is Action<P> => action.type === actionCreator.type;

export default isAction;
