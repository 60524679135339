/**
 * External dependencies
 */
import { FC } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';

/**
 * Internal dependencies.
 */
import { FormFieldStatus } from '@/types';

export type FormFieldStatusIconProps = {
	status?: FormFieldStatus;
} & AntdIconProps;

const FormFieldStatusIcon: FC<FormFieldStatusIconProps> = ({
	status,
	...props
}) => (
	<>
		{status === 'success' && <CheckCircleOutlined {...props} />}
		{status === 'error' && <CloseCircleOutlined {...props} />}
	</>
);

export default FormFieldStatusIcon;
