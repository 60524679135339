/**
 * External dependencies
 */
import { Reducer } from 'redux';

/**
 * Internal dependencies
 */
import { InvitationModel } from '@/invitation';
import { setInvitationData } from '@/store/actions/invitation';
import initialState from '../../initialState';
import isAction from '../../isAction';

const data: Reducer<InvitationModel | null> = (
	state = initialState.invitation.data,
	action
) => (isAction(action, setInvitationData) ? action.payload : state);

export default data;
