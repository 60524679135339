/**
 * External dependencies
 */
import { configureStore } from '@reduxjs/toolkit';
import {
	useDispatch as useReduxDispatch,
	useSelector as useReduxSelector,
	useStore as useReduxStore,
} from 'react-redux';

/**
 * Internal dependencies
 */
import { AppStore, Dispatch, RootState } from './types';
import { PERSIST, persistStore } from 'redux-persist';
import { reducer } from './reducer';
import initialState from './initialState';

export const createStore = () =>
	configureStore({
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: {
					ignoredActions: [PERSIST],
				},
			}),
		reducer,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		preloadedState: initialState as any,
	});

export const store = createStore();

export const persistor = persistStore(store);

export const useDispatch = useReduxDispatch.withTypes<Dispatch>();
export const useSelector = useReduxSelector.withTypes<RootState>();
export const useStore = useReduxStore.withTypes<AppStore>();

export { default as StoreProvider } from './provider';
export { initialState };
export * from './action-creators';
export * from './actions';
export * from './reducer';
