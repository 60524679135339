/**
 * External dependencies
 */
import { RecordModel } from 'pocketbase';

/**
 * Internal dependencies
 */
import { Event } from './types';
import { EventsList } from '@/types';

export const isEventsListModel = (event: Event): event is EventsList =>
	(event as any as RecordModel).collectionName === 'events_list';
