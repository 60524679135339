/**
 * External dependencies
 */
import { createContext, useContext } from 'react';

/**
 * Internal dependencies
 */
import { useAuthUtils } from './hooks';

export type AuthContextType = ReturnType<typeof useAuthUtils>;

export const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
	const auth = useContext(AuthContext);

	if (!auth) {
		throw new Error('useAuth must be used within a AuthProvider');
	}

	return auth;
};
