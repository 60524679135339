/**
 * Internal dependencies
 */
import { AvatarUploadCallback } from '@/user/types';
import useUpdateUser from '../useUpdateUser';

const useHandleAvatarUpload = (): AvatarUploadCallback => {
	const [update] = useUpdateUser();

	return async (options) => update({ avatar: options.file as File });
};

export default useHandleAvatarUpload;
