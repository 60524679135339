/**
 * External dependencies
 */
import { FC, PropsWithChildren } from 'react';
import { Provider } from 'react-redux';

/**
 * Internal dependencies
 */
import { persistor, store } from '.';
import { PersistGate } from 'redux-persist/integration/react';

type StoreProviderProps = PropsWithChildren;

const StoreProvider: FC<StoreProviderProps> = ({ children }) => (
	<Provider store={store}>
		<PersistGate persistor={persistor}>{children}</PersistGate>
	</Provider>
);

export default StoreProvider;
