/**
 * External dependencies
 */
import { Divider } from 'antd';
import { FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { Button } from '@/components';
import { ReactComponent as ChevronRight } from '@/images/chevron-right-large.svg';
import classes from '../EventIntro.module.scss';

export type ActionsProps = {
	cancelLabel?: string;
	continueLabel?: string;
	onCancel?: () => void;
	onContinue?: () => void;
};

const cx = classNames.bind(classes);

const Actions: FC<ActionsProps> = ({
	cancelLabel,
	continueLabel,
	onCancel,
	onContinue,
}) => {
	const hasContinueAction = continueLabel && onContinue;
	const hasCancelAction = cancelLabel && onCancel;

	if (!hasContinueAction && !hasCancelAction) {
		return;
	}

	return (
		<div className={cx('actions')}>
			{hasContinueAction && (
				<Button
					className={cx('continue-button')}
					icon={<ChevronRight />}
					onClick={onContinue}
					size="large"
				>
					{continueLabel}
				</Button>
			)}
			{hasContinueAction && hasCancelAction && <Divider>or</Divider>}
			{hasCancelAction && (
				<Button
					variant="link"
					color="secondary"
					size="large"
					onClick={onCancel}
				>
					{cancelLabel}
				</Button>
			)}
		</div>
	);
};

export default Actions;
