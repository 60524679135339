/**
 * External dependencies
 */
import { isPast } from 'date-fns';

/**
 * Internal dependencies
 */
import { Event, EventModel } from '@/event/types';
import { getUserModel } from '@/user';
import { isEventsListModel } from '@/event/type-guards';
import { pb } from '@/pocketbase';

type GetEventModelReturn<T> = T extends Event
	? EventModel
	: T extends undefined
		? null
		: never;

const getEventModel = <T extends Event | undefined>(
	event: T
): GetEventModelReturn<T> => {
	if (!event) {
		return null;
	}

	const { attendees, author, expand, ...rest } = event;

	return {
		...rest,
		author: expand?.author ? getUserModel(expand?.author) : null,
		attendees:
			expand?.attendees?.map((attendee) => getUserModel(attendee)) ?? [],
		hasJoined:
			(!!pb.authStore.record?.id &&
				attendees?.includes(pb.authStore.record.id)) ??
			false,
		isClosed: isPast(event.endsAt),
		responsesCount: isEventsListModel(event) ? event.responsesCount : null,
	};
};

export default getEventModel;
