/**
 * External dependencies
 */
import { FC } from 'react';
import { validate } from 'email-validator';

/**
 * Internal dependencies
 */
import { TagsInputProps } from '../TagsInput/TagsInput';
import TagsInput from '../TagsInput';

const EmailTagsInput: FC<TagsInputProps> = (props) => (
	<TagsInput
		validateTag={(tag) => validate(tag.text)}
		placeholder="john@email.com..."
		{...props}
	/>
);

export default EmailTagsInput;
