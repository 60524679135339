/**
 * Internal dependencies
 */
import { EmailPasswordFormProps } from '@/forms/components/EmailPasswordForm/EmailPasswordForm';
import { SignUpFormFields } from '@/sign-up';
import { useAuth } from '@/auth';

type UseSignUpFormControllerReturn = Pick<
	EmailPasswordFormProps<SignUpFormFields, true, true, true>,
	'error' | 'onFinish' | 'onSocialButtonClick' | 'processing'
>;

const useSignUpFormController = (): UseSignUpFormControllerReturn => {
	const { loginWithProvider, register, loading, error } = useAuth();

	return {
		error: error ? 'Invalid email or password' : undefined,
		onFinish: register,
		onSocialButtonClick: loginWithProvider,
		processing: loading,
	};
};

export default useSignUpFormController;
