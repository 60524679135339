/**
 * Internal dependencies
 */
import { breakpoints } from '@/config';
import useMatchMedia from '../useMatchMedia';

type Breakpoint = keyof typeof breakpoints;

const useMatchBreakpoint = (breakpoint: Breakpoint) =>
	useMatchMedia(`(max-width: ${breakpoints[breakpoint]}px)`);

export default useMatchBreakpoint;
