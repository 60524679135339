/**
 * External dependencies
 */
import { Form, FormItemProps } from 'antd';
import { FC } from 'react';

/**
 * Internal dependencies
 */
import PasswordInput from '../PasswordInput';
import PasswordConfirmFormItem from '../PasswordConfirmFormItem';

type PasswordFormFieldsProps = {
	withConfirmation?: boolean;
} & FormItemProps;

const PasswordFormFields: FC<PasswordFormFieldsProps> = ({
	withConfirmation,
	...props
}) => (
	<>
		<Form.Item
			label="Password"
			name="password"
			rules={[{ required: true }]}
			{...props}
		>
			<PasswordInput />
		</Form.Item>
		{withConfirmation && <PasswordConfirmFormItem />}
	</>
);

export default PasswordFormFields;
