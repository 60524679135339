/**
 * External dependencies
 */
import { FC } from 'react';
import { Progress as BaseProgess, ProgressProps } from 'antd';

const Progress: FC<ProgressProps> = ({
	status = 'normal',
	showInfo = false,
	...props
}) => <BaseProgess {...{ status, showInfo }} {...props} />;

export default Progress;
