/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import { Column } from '@/components';
import { ReactComponent as PowerOff } from '@/images/power-off.svg';
import { ReactComponent as SettingsButton } from '@/images/settings-button.svg';
import { Button } from '@/components';
import { Route } from '@/router';

export type AccountActionsProps = {
	onLogOut?: () => void;
};

const AccountActions: FC<AccountActionsProps> = ({ onLogOut }) => (
	<Column gap={14}>
		<Button icon={<SettingsButton />} href={Route.Account}>
			Manage your account
		</Button>
		<Button icon={<PowerOff />} variant="outlined" onClick={onLogOut}>
			Log Out
		</Button>
	</Column>
);

export default AccountActions;
