/**
 * External dependencies
 */
import { FC } from 'react';
import { Link, useLocation } from 'react-router';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { NavigationItem } from '@/types';
import classes from './Navigation.module.scss';

const cx = classNames.bind(classes);

type NavigationProps = {
	className?: string;
	itemClassName?: string;
	itemActiveClassName?: string;
	items: NavigationItem[];
};

const Navigation: FC<NavigationProps> = ({
	className,
	itemClassName,
	itemActiveClassName,
	items,
}) => {
	const currentPathname = useLocation().pathname;

	return (
		<nav className={cx('navigation', className)}>
			{items.map(({ icon, label, pathname }, index) => (
				<Link
					key={index}
					className={cx('item', itemClassName, {
						active: currentPathname === pathname,
						[itemActiveClassName ?? '']:
							itemActiveClassName && currentPathname === pathname,
					})}
					to={pathname}
				>
					{icon} {label}
				</Link>
			))}
		</nav>
	);
};

export default Navigation;
