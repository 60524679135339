/**
 * External dependencies
 */
import { useInvitationHandler } from '@/invitation/hooks';
import { FC } from 'react';

const InvitationHandler: FC = () => {
	useInvitationHandler();

	return null;
};

export default InvitationHandler;
