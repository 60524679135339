/**
 * Internal dependencies
 */
import { ClientResponseError } from 'pocketbase';
import { ErrorData, ErrorWithCode, FieldErrors } from './types';

export const isErrorWithCode = (data: unknown): data is ErrorWithCode =>
	!!data &&
	typeof data === 'object' &&
	'code' in data &&
	typeof data.code === 'string' &&
	'message' in data &&
	typeof data.message === 'string';

export const isFieldErrorsObject = (data: unknown): data is FieldErrors =>
	!!data &&
	typeof data === 'object' &&
	!Object.values(data).find((item) => !isErrorWithCode(item));

export const getFieldErrors = (error: unknown): FieldErrors | null =>
	error instanceof ClientResponseError && isFieldErrorsObject(error.data.data)
		? error.data.data
		: null;

export const getFieldErrorMessage = (
	data: ErrorData | null | undefined,
	field: string,
	messages?: Record<string, string>
) =>
	data?.fields && data.fields[field]
		? (messages?.[data.fields[field].code] ?? data.fields[field].message)
		: null;
