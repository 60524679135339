/**
 * External dependencies
 */
import { useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { Route } from '@/router';
import { setCurrentEvent, useDispatch } from '@/store';
import useEventActivitiesSync from '../useEventActivitiesSync';
import useEventResponsesSync from '../useEventResponsesSync';
import useEvents from '../useEvents';
import useReactionsSync from '../useReactionsSync';

const useCurrentEventSync = () => {
	const { events, loading } = useEvents();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();

	const currentEvent = useMemo(
		() =>
			params.eventId
				? events?.find((event) => event.id === params.eventId)
				: undefined,
		[events, params.eventId]
	);

	const mountedRef = useRef(false);

	useEventResponsesSync(currentEvent?.id);
	useEventActivitiesSync();
	useReactionsSync();

	useEffect(() => {
		if (mountedRef.current && !currentEvent && !loading) {
			navigate(Route.Home, { replace: true });
		}

		mountedRef.current = true;
	}, [currentEvent, loading, navigate]);

	useEffect(() => {
		dispatch(setCurrentEvent(currentEvent ?? null));

		return () => {
			dispatch(setCurrentEvent(null));
		};
	}, [currentEvent, dispatch]);
};

export default useCurrentEventSync;
