/**
 * External dependencies
 */
import { FC } from 'react';
import Icon from '@ant-design/icons';
import Password, { PasswordProps } from 'antd/es/input/Password';

/**
 * Internal dependencies
 */
import { ReactComponent as EyeIcon } from '@/images/icon-eye.svg';
import { ReactComponent as KeyIcon } from '@/images/icon-key.svg';

const PasswordInput: FC<PasswordProps> = (props) => (
	<Password
		iconRender={() => <Icon component={EyeIcon} />}
		prefix={<KeyIcon />}
		size="large"
		{...props}
	/>
);

export default PasswordInput;
