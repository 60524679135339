/**
 * Internal dependencies
 */
import { signUpRoutes, useCurrentRoute } from '@/router';

const useSignUpProgress = () => {
	const currentRoute = useCurrentRoute();

	const step = currentRoute ? signUpRoutes.indexOf(currentRoute) + 1 : 0;
	const totalSteps = signUpRoutes.length;

	return { step, totalSteps, progress: (step / totalSteps) * 100 };
};

export default useSignUpProgress;
