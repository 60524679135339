/**
 * External dependencies
 */
import { Reducer } from 'redux';

/**
 * Internal dependencies
 */
import { EventActivityModel } from '@/event';
import { setEventActivities } from '../../actions';
import initialState from '../../initialState';
import isAction from '../../isAction';

const activities: Reducer<EventActivityModel[] | null> = (
	state = initialState.currentEvent.activities,
	action
) => (isAction(action, setEventActivities) ? action.payload : state);

export default activities;
