/**
 * External dependencies
 */
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

/**
 * Internal dependencies
 */
import { setAuthReferrer, useDispatch } from '@/store';
import { Route } from '@/router';

const useHandleReferrer = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();

	useEffect(() => {
		if (params.referrer) {
			dispatch(setAuthReferrer(params.referrer));
			navigate(Route.SignUp, { replace: true });
		}
	}, [dispatch, navigate, params]);
};

export default useHandleReferrer;
