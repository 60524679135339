/**
 * External dependencies
 */
import { FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import { Column, DualFontText, Text, Title } from '@/components';
import classes from './FormHeader.module.scss';

const cx = classNames.bind(classes);

export type FormHeaderProps = {
	centered?: boolean;
	className?: string;
	subtitle?: string;
	title: [string, string];
};

const FormHeader: FC<FormHeaderProps> = ({
	centered,
	className,
	subtitle,
	title,
}) => (
	<Column className={cx('header', className, { centered })} gap={6}>
		<Title level={2} className={classes.title} color="primary">
			<DualFontText
				parts={title}
				postClassName="font-dm-sans"
				size="large"
			/>
		</Title>
		{subtitle && <Text className={classes.subtitle}>{subtitle}</Text>}
	</Column>
);

export default FormHeader;
