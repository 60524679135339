/**
 * External dependencies
 */
import { isFuture } from 'date-fns';

/**
 * Internal dependencies
 */
import { useEvents } from '@/event';

const useOngoingEvent = () => {
	const { events, error, loading } = useEvents();

	return {
		event: events?.find((event) => isFuture(event.endsAt)) ?? null,
		error,
		loading,
	};
};

export default useOngoingEvent;
