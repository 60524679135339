/**
 * External dependencies
 */
import { FC } from 'react';
import { UploadFile } from 'antd/es/upload';
import classNames from 'classnames/bind';
import FormItem from 'antd/es/form/FormItem';
import TextArea from 'antd/es/input/TextArea';

/**
 * Internal dependencies
 */

import { UploadChangeParam } from 'antd/es/upload';
import AnswerAttachments from '../AnswerAttachments';
import classes from './AnswerInput.module.scss';

type AnswerInputProps = {
	accept?: string;
	className?: string;
	maxLength?: number;
	placeholder?: string;
};

const cx = classNames.bind(classes);

const AnswerInput: FC<AnswerInputProps> = ({
	accept = 'audio/*,image/*,video/*',
	className,
	maxLength = 300,
	placeholder = 'Write your answer...',
}) => {
	return (
		<div className={cx('wrap', className)}>
			<FormItem
				className={cx('content-item')}
				name="content"
				dependencies={['files']}
				rules={[
					({ getFieldValue }) => ({
						validator: (_, value) =>
							!value &&
							!(
								getFieldValue('files') as
									| UploadFile[]
									| undefined
							)?.length
								? Promise.reject(
										'Please write an answer or attach at least one file.'
									)
								: Promise.resolve(),
					}),
				]}
			>
				<TextArea
					autoSize
					className={cx('content-input')}
					count={{ show: true }}
					maxLength={maxLength}
					placeholder={placeholder}
				/>
			</FormItem>
			<FormItem
				className={cx('attachments-item')}
				getValueFromEvent={(event?: UploadChangeParam) =>
					event?.fileList
				}
				name="files"
				valuePropName="fileList"
			>
				<AnswerAttachments
					accept={accept}
					className={cx('attachments-button')}
					multiple
				/>
			</FormItem>
		</div>
	);
};

export default AnswerInput;
