/**
 * External dependencies
 */
import { FC, PropsWithChildren, ReactNode } from 'react';
import { Layout } from 'antd';

/**
 * Internal dependencies
 */
import { Footer, Header } from '@/components';
import { useHandleAvatarUpload } from '@/user';
import classes from './HalfScreenLayout.module.scss';
import placeholderImage from '@/images/half-screen-placeholder.png';

type HalfScreenLayoutProps = PropsWithChildren<{
	beforeContent?: ReactNode;
	image?: string;
}>;

const HalfScreenLayout: FC<HalfScreenLayoutProps> = ({
	beforeContent,
	children,
	image = placeholderImage,
}) => (
	<>
		<div className={classes.wrap}>
			<Header handleAvatarUpload={useHandleAvatarUpload()} />
			<div className={classes.content}>
				{beforeContent}
				<Layout.Content className={classes.inner}>
					{children}
				</Layout.Content>
			</div>
			<Footer />
		</div>
		<Layout.Sider className={classes.sider} width="50%">
			<img alt="" className={classes.image} src={image} />
		</Layout.Sider>
	</>
);

export default HalfScreenLayout;
