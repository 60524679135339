/**
 * External dependencies
 */
import {
	Checkbox as AntCheckbox,
	CheckboxProps as AntCheckboxProps,
	CheckboxRef,
} from 'antd';
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import classes from './Checkbox.module.scss';

const cx = classNames.bind(classes);

type CheckboxProps = AntCheckboxProps & {
	size?: 'small' | 'large';
};

type CheckboxComponent = ForwardRefExoticComponent<
	CheckboxProps & RefAttributes<CheckboxRef>
> & {
	Group: typeof AntCheckbox.Group;
};

const Checkbox: CheckboxComponent = Object.assign(
	forwardRef<CheckboxRef, CheckboxProps>(
		({ rootClassName, size = 'small', ...props }, ref) => (
			<AntCheckbox
				ref={ref}
				rootClassName={cx('checkbox', rootClassName, size)}
				{...props}
			/>
		)
	)
);

Checkbox.Group = AntCheckbox.Group;

export default Checkbox;
