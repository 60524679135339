/**
 * External dependencies
 */
import { ConfigProvider } from 'antd';
import { FC, PropsWithChildren } from 'react';

/**
 * Internal dependencies
 */
import { avatarSize } from '@/config';
import { getCssVar } from '@/utils';

const AntdConfig: FC<PropsWithChildren> = (props) => (
	<ConfigProvider
		theme={{
			components: {
				Avatar: {
					containerSize: avatarSize.default,
					containerSizeLG: avatarSize.large,
					containerSizeSM: avatarSize.small,
				},
				Button: {
					borderColorDisabled: getCssVar('color-neutral400'),
					colorBgContainer: 'transparent',
					colorBgContainerDisabled: getCssVar('color-neutral100'),
					colorBorder: getCssVar('color-primary900'),
					colorPrimary: getCssVar('color-primary900'),
					colorPrimaryActive: getCssVar('color-primary950'),
					colorPrimaryHover: getCssVar('color-primary950'),
					colorPrimaryTextActive: getCssVar('color-primary950'),
					colorPrimaryTextHover: getCssVar('color-primary950'),
					colorTextDisabled: getCssVar('color-neutral400'),
					controlHeight: 40,
					controlHeightLG: 44,
					controlHeightSM: 28,
					defaultColor: getCssVar('color-primary900'),
					defaultShadow: 'none',
					fontSize: 16,
					fontWeight: 700,
					lineWidth: 2,
					paddingBlock: 5,
					paddingBlockLG: 5,
					paddingBlockSM: 0,
					paddingInline: 24,
					paddingInlineLG: 24,
					paddingInlineSM: 0,
					primaryShadow: 'none',
				},
				Checkbox: {
					colorBorder: getCssVar('color-neutral200'),
					borderRadiusSM: 2,
					fontSize: 10,
				},
				Divider: {
					textPaddingInline: '0.5rem',
				},
				Form: {
					itemMarginBottom: 20,
					labelColor: getCssVar('color-neutral900'),
					labelFontSize: 12,
					labelHeight: 'auto',
				},
				Input: {
					colorBgContainer: getCssVar('color-neutral100'),
					inputFontSize: 14,
					inputFontSizeLG: 18,
					lineHeight: 1.4,
					lineHeightLG: 1.4,
					paddingBlock: 6,
					paddingBlockLG: 10,
				},
				Layout: {
					bodyBg: getCssVar('color-neutral100'),
					footerBg: 'transparent',
					footerPadding: '0.875rem 1.25rem',
					headerBg: getCssVar('color-white'),
					siderBg: getCssVar('color-white'),
				},
				Progress: {
					defaultColor: getCssVar('color-secondary900'),
					remainingColor: getCssVar('color-secondary700'),
				},
				Select: {
					optionFontSize: 14,
					optionHeight: 32,
					optionLineHeight: 1,
					optionPadding: '8px 10px',
					optionSelectedBg: getCssVar('color-neutral200'),
					optionSelectedColor: getCssVar('color-black'),
					optionSelectedFontWeight: 400,
					selectorBg: getCssVar('color-neutral100'),
				},
				Switch: {
					colorPrimary: getCssVar('color-primary900'),
					colorPrimaryHover: getCssVar('color-primary900'),
					fontSize: 14,
					fontSizeSM: 10,
					handleBg: getCssVar('color-neutral300'),
					handleShadow: 'none',
					handleSize: 14,
					handleSizeSM: 10,
					lineHeight: 20 / 14,
					lineHeightSM: 1.6,
				},
				Typography: {
					colorText: getCssVar('color-neutral900'),
					colorTextHeading: getCssVar('color-neutral900'),
				},
			},
			token: {
				colorError: getCssVar('color-error900'),
				colorLink: getCssVar('color-secondary900'),
				colorPrimary: getCssVar('color-primary900'),
				colorSplit: getCssVar('color-neutral100'),
				fontFamily: getCssVar('font-inter'),
				fontSize: 18,
				lineHeight: 1.5,
				linkDecoration: 'underline',
			},
		}}
		{...props}
	/>
);

export default AntdConfig;
