/**
 * External dependencies
 */
import { FC } from 'react';
import classNames from 'classnames/bind';

/**
 * Internal dependencies
 */
import classes from './UserCountPill.module.scss';

const cx = classNames.bind(classes);

type UserCountPillProps = {
	current: number;
	max: number;
};

const UserCountPill: FC<UserCountPillProps> = ({ current, max }) => (
	<div className={cx('wrap')}>
		<span className={cx('current')}>{current}</span>
		<span className={cx('max', 'font-sofia-pro')}>/{max}</span>
	</div>
);

export default UserCountPill;
